import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../service/firebase";
import { STATUS } from "../utils/constants";
import Logger from "../utils/Logger";
import { IPet } from "../interfaces/pet";
import { GetPetPayload } from "../interfaces/payload";
import { IdParam } from "../interfaces/param";
import { AppError } from "../utils/errors";

export const getPets = createAsyncThunk("pets/get", async (data: any) => {
  try {
    const petsList: IPet[] = [];

    for (const favorite of data.favorites) {
      const docRef = doc(db, "pets", favorite);
      const docSnap = await getDoc(docRef);
      const res = docSnap.data() as any;
      const pet = {
        name: res.nombre,
        picture: res.picture,
        especie: res.especie,
        raza: res.raza,
      } as IPet;
      petsList.push(pet);
    }

    return petsList;
  } catch (error) {
    Logger.error("Pets ::: getPets ::: Could not get pets", error, data);
    throw error;
  }
});

export const getPetFromShortUid = createAsyncThunk<GetPetPayload, IdParam>(
  "pets/getPetFromShortUid",
  async (data: IdParam) => {
    try {
      const { id } = data;
      if (!id) return { pet: null };
      const collection_name = "pets";
      const collection_ref = collection(db, collection_name);
      const q = query(collection_ref, where("shortUid", "==", id));
      const snapshot = await getDocs(q);
      if (snapshot.docs.length === 0) return { pet: null };
      const petData = snapshot.docs[0].data() as any;
      const pet = {
        name: petData.nombre || petData.name,
        picture: petData.picture,
        medicalRecordUid: petData.medicalRecordUid,
        userUid: petData.userUid,
        especie: petData.especie,
        raza: petData.raza,
        uid: snapshot.docs[0].id,
      } as IPet;
      if (pet.userUid) {
        const userDocRef = doc(db, "users", pet.userUid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data() as any;
        pet.user = userData;
      }
      return { pet };
    } catch (error) {
      Logger.error(
        "Pets ::: getPetFromShortUid ::: Could not get pets",
        error,
        data,
      );
      throw new AppError("Could not get pets", error);
    }
  },
);

const initialState: PetState = {
  pet: null,
  pets: [],
  getPetStatus: STATUS.IDLE,
  getFromUidStatus: STATUS.IDLE,
};

export interface PetState {
  pet: IPet | null;
  pets: IPet[];
  getPetStatus: STATUS;
  getFromUidStatus: STATUS;
}

export const PetsSlice = createSlice({
  name: "pets",
  initialState: initialState,
  reducers: {
    clearPetState: (state) => {
      state.pet = null;
      state.getPetStatus = STATUS.IDLE;
      state.getFromUidStatus = STATUS.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPets.pending, (state, action) => {
      Logger.log("Fetching getPets.....");
      state.getPetStatus = STATUS.FETCHING;
    });
    builder.addCase(getPets.fulfilled, (state, action) => {
      Logger.log("Fetched getPets.....");
      state.pets = action.payload;
      state.getPetStatus = STATUS.FETCH;
    });
    builder.addCase(getPets.rejected, (state, action) => {
      Logger.log("Failed getPets.....");
      state.getPetStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(getPetFromShortUid.pending, (state, action) => {
      Logger.log("Fetching getPetFromShortUid.....");
      state.getFromUidStatus = STATUS.FETCHING;
    });
    builder.addCase(getPetFromShortUid.fulfilled, (state, action) => {
      Logger.log("Fetched getPetFromShortUid.....");
      state.pet = action.payload.pet;
      state.getFromUidStatus = STATUS.FETCH;
    });
    builder.addCase(getPetFromShortUid.rejected, (state, action) => {
      Logger.log("Failed getPetFromShortUid.....");
      state.getFromUidStatus = STATUS.FETCH_ERROR;
    });
  },
});

export const { clearPetState } = PetsSlice.actions;

export default PetsSlice.reducer;
