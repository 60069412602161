export interface Intervals {
  startTime: string;
  endTime: string;
}

// export interface IntervalsByDay {
//   monday: Intervals[];
//   tuesday: Intervals[];
//   wednesday: Intervals[];
//   thursday: Intervals[];
//   friday: Intervals[];
//   saturday: Intervals[];
//   sunday: Intervals[];
// }

export type IntervalsType = {
  [key: string]: Intervals[];
};

export const EMPTY_INTERVALS: IntervalsType = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
};

export enum STATUS {
  IDLE,
  FETCHING,
  FETCH,
  FETCH_ERROR,
}

export enum APPOINTMENT_STATUS {
  WAITING,
  IDLE,
  PENDING,
  CANCELED,
  CONFIRMED,
  LOCKED,
}

export const vaccineDropdownList: string[] = [
  "Antirrábica",
  "Leptospirosis",
  "Leucemia Felina",
  "Quintuple",
  "Sextuple",
  "Tos de las perreras",
  "Triple Felina",
];

export const especies = [
  "Ave",
  "Conejo",
  "Gato",
  "Hamster",
  "Huron",
  "Mini Pig",
  "Otro",
  "Perro",
  "Pez",
  "Raton",
  "Reptil",
  "Tortuga",
];

export type RazasType = {
  [key: string]: string[];
};

export const razas: RazasType = {
  perro: [
    "Mestizo",
    "Airedale terrier",
    "Akita inu",
    "Akita americano",
    "American Bully",
    "American Short Hair",
    "American staffordshire terrier",
    "American stanford",
    "Australian Catle dog",
    "Australian Labrador",
    "Basenji",
    "Basset Hound",
    "Beagle",
    "Bearded Collie",
    "Beauceron",
    "Bichón Frisé",
    "Bichón Habanero",
    "Bloodhound (Sabueso de San Huberto)",
    "Bobtail",
    "Border Collie",
    "Border terrier",
    "Boston terrier",
    "Boxer",
    "Boyero de Berna",
    "Braco Alemán",
    "Bretón ",
    "Bull terrier",
    "Bulldog americano",
    "Bulldog inglés",
    "Bulldog francés",
    "Bullmastiff",
    "Cairn terrier",
    "Cane Corso",
    "Caniche (Poodle)",
    "Caniche Toy ",
    "Caniche Micro Toy ",
    "Cavalier King Charles Spaniel",
    "Chihuahua",
    "Chow Chow",
    "Clumber Spaniel",
    "Cocker Spaniel",
    "Collie ",
    "Collie enano",
    "Corgi",
    "Cotón de Tuléar",
    "Crestado Chino",
    "Dachshund (salchicha)",
    "Dachshund pelo duro",
    "Dálmata",
    "Dobermann",
    "Dogo Argentino",
    "Dogo de Burdeos",
    "Dogo del Tibet",
    "Drentse Partridge Dog",
    "Drever",
    "Dunker",
    "Fila Brasileiro",
    "Finnish Lapphound",
    "Fox terrier de pelo de alambre",
    "Fox terrier de pelo liso",
    "Galgo",
    "Galgo afgano",
    "Galgo Italiano",
    "Golden Retriever",
    "Gran Danés ",
    "Hovawart",
    "Husky Siberiano (Siberian Husky)",
    "Jack Russell",
    "Keeshond",
    "King Cavallier",
    "Komondor",
    "Kuvasz",
    "Labrador",
    "Labradoodle",
    "Malamute de Alaska",
    "Maltés",
    "Manchester terrier",
    "Mastiff",
    "Mastín Napolitano",
    "Ovejero alemán",
    "Ovejero belga",
    "Ovejero blanco",
    "Ovejero suizo",
    "Pastor australiano",
    "Pastor belga",
    "Pastor catalán",
    "Pastor de Brie",
    "Pastor del Caucas",
    "Pastor Holandés",
    "Pastor Inglés",
    "Pastor Suizo",
    "Pequinés",
    "Pit Bull Terrier",
    "Pointer",
    "Pomeranian",
    "Poodle",
    "Pug",
    "Rhodesian",
    "Rottweiler",
    "Samoyedo",
    "San Bernardo",
    "Schipperke",
    "Schnauzer estándar",
    "Schnauzer gigante ",
    "Schnauzer miniatura ",
    "Scottish terrier",
    "Setter Inglés",
    "Setter Irlandés",
    "Shar Pei",
    "Shiba Inu",
    "Shih Tzu",
    "Shikoku",
    "Slovensky Cuvac",
    "Staffordshire bull terrier",
    "Staffordshire terrier americano",
    "Terranova (Newfoundland)",
    "Terrier brasilero",
    "Terrier negro ruso",
    "Terrier tibetano",
    "Tosa",
    "Viejo Pastor Inglés",
    "Vizsla",
    "Volpino Italiano",
    "Weimaraner",
    "Welsh terrier",
    "West highland white terrier",
    "Whippet",
    "Yorkshire Terrier",
  ],
  gato: [
    "Común europeo",
    "Balines",
    "Bengal",
    "Birmano",
    "Bosques de Noruega",
    "Británico pelo largo",
    "British Shorthair",
    "Chartreavy",
    "Esfinge",
    "Exótico",
    "Himalayo",
    "Maine Coon",
    "Mans",
    "Mau Egipcio",
    "Mestizo",
    "Orange Tabi",
    "Oriental",
    "Persa",
    "Ragdoll",
    "Russian Blue",
    "Sagrado de Birmania",
    "Scotish",
    "Seal Point",
    "Siamés",
    "Siamés Tabi",
    "Siberiano del Bosque",
    "Sphynx",
    "Tabby",
    "Torkinés",
  ],
  conejo: [
    "Angora inglés",
    "Belier holandés",
    "Conejo americano",
    "Conejo arlequín",
    "Conejo cabeza de león",
    "Conejo californiano",
    "Conejo del Himalaya",
    "Conejo holandés",
    "Conejo holandés enano",
    "Enano hotot",
    "Gigante de checkered",
    "Jersey Wooly",
    "Lop inglés",
    "Mestizo",
    "Mini Lop",
    "Mini Rex",
  ],
  ave: [
    "No lo se",
    "Cacatúa",
    "Canario",
    "Cotorra",
    "Diamante",
    "Gallina",
    "Ganso",
    "Loro",
    "Ninfa",
    "Tórtola",
    "Pato",
    "Periquito",
  ],
  huron: ["Huron"],
  tortuga: ["Tortuga"],
  raton: ["Raton"],
  pez: ["Pez"],
  otro: ["Otro"],
  reptil: ["reptil"],
  hamster: ["Hamster"],
  "mini pig": ["Mini Pig"],
};

export const appDataTags = {
  banco_de_sangre: [],
  centro_de_adopcion: [],
  clinica_veterinaria: [
    {
      tags: ["internación", "petshop", "peluqueria", "traslados"],
      title: "servicios_que_ofrezco",
    },
    {
      tags: ["24hs", "urgencias"],
      title: "atiendo",
    },
  ],
  cuidador: [
    {
      tags: ["casa", "departamento", "jardin", "campo"],
      title: "tipo_de_vivienda",
    },
  ],
  entrenador: [
    {
      tags: ["grupal", "singular"],
      title: "tipo_de_entrenamiento",
    },
  ],
  medico_veterinario: [
    {
      tags: [
        "cardiologia",
        "cirugia",
        "clinica",
        "dermatologia",
        "ecografia",
        "endocrinologia",
        "endoscopia",
        "etologia",
        "fisiatria",
        "gastroenterologia",
        "hematologia",
        "inmunologia",
        "kinesiologia",
        "neumonologia",
        "neurologia",
        "nefrologia",
        "nutricion",
        "oftalmologia",
        "oncologia",
        "radiologia",
        "traumatologia",
        "acupuntura",
        "exoticos",
        "homeopatia",
        "felinos",
        "anestesiología",
        "Endoscopia",
        "terapia_neural",
      ],
      title: "especialidad",
    },
    {
      tags: ["teleconsulta", "atiende_a_domicilio", "atiende_en_consultorio"],
      title: "modalidad_de_atencion",
    },
  ],
  paseador: [
    {
      tags: ["grupal", "singular"],
      title: "tipo_de_paseo",
    },
  ],
  peluqueria: [],
  petshop: [
    {
      tags: ["peluqueria"],
      title: "ofrezco",
    },
  ],
  transportista: [],
};

interface IFormFieldConstant {
  id: string;
  label: string;
  type: string;
  required: boolean;
  icon: string;
  readOnly?: boolean;
  options?: string[];
}

export const formFieldsConstant: IFormFieldConstant[] = [
  {
    id: "petName",
    label: "Nombre Mascota",
    type: "text",
    required: true,
    icon: "paw",
  },
  {
    id: "userName",
    label: "Nombre y apellido tutor",
    type: "text",
    required: true,
    icon: "user",
  },
  {
    id: "mobileNumber",
    label: "Número de teléfono",
    type: "number",
    required: true,
    icon: "phone",
  },
  {
    id: "email",
    label: "Email",
    type: "email",
    required: true,
    icon: "envelope",
  },
  {
    id: "description",
    label: "Motivo",
    type: "text",
    required: true,
    icon: "pen",
  },
  {
    id: "especie",
    label: "Especie",
    type: "text",
    required: true,
    icon: "paw",
    options: especies,
  },
  {
    id: "raza",
    label: "Raza",
    type: "text",
    required: true,
    icon: "paw",
    options: razas[especies[0].toLowerCase()],
  },
  {
    id: "address",
    label: "Dirección",
    type: "text",
    required: false,
    icon: "location-dot",
  },
];

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const colors = [
  "#FFC0CB",
  "#D8BFD8",
  "#F5DEB3",
  "#FFFF99",
  "#87CEEB",
  "#FFA07A",
  "#FFDAB9",
  "#E6E6FA",
  "#00CED1",
  "#C0C0C0",
  "#FFB6C1",
  "#98FB98",
  "#FFD700",
  "#B0E0E6",
  "#FFA500",
  "#FFE4C4",
  "#7FFFD4",
  "#D8BFD8",
  "#FAFAD2",
  "#FF69B4",
  "#FFE4E1",
  "#7CFC00",
  "#20B2AA",
  "#FF6347",
  "#F0E68C",
  "#DDA0DD",
  "#ADFF2F",
  "#66CDAA",
  "#FFA07A",
  "#FAF0E6",
  "#FF00FF",
  "#7FFF00",
  "#AFEEEE",
  "#FF8C00",
  "#DA70D6",
  "#00FF7F",
  "#00FA9A",
  "#FF4500",
  "#F5F5DC",
  "#BA55D3",
  "#32CD32",
  "#00FFFF",
  "#FF7F50",
  "#F5F5F5",
  "#9932CC",
  "#3CB371",
  "#E0FFFF",
  "#FF6EB4",
  "#FFFACD",
  "#8A2BE2",
  "#90EE90",
];
export const prepositions: string[] = [
  "a",
  "ante",
  "bajo",
  "con",
  "de",
  "desde",
  "en",
  "entre",
  "hacia",
  "hasta",
  "para",
  "por",
  "sin",
  "sobre",
  "tras",
];
export const articles: string[] = [
  "el",
  "la",
  "los",
  "las",
  "un",
  "una",
  "unos",
  "unas",
];

export const categorySettings = {
  enableOwnerLink: {
    type: "checkbox",
    value: false,
  },
};
