import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../service/firebase";
import Logger from "../utils/Logger";
import { EMPTY_INTERVALS, IntervalsType, STATUS } from "../utils/constants";
import {
  addIntervalIntoIntervalDays,
  fillAllIntervalsCalendarOnEdit,
  getArray,
  getCalendarListFromQuery,
  getConvertedMoment,
  getMomentFromTimestamp,
  removeIntervalFromIntervalDays,
  sanitize,
  updateIntervalFromIntervalDays,
  updateIntervalItemFromIntervalDays,
} from "../utils/functions";
import { DocRequest } from "../interfaces/request";
import { Moment, duration } from "moment-timezone";
import {
  GetCalendarPayload,
  GetCalendarsPayload,
  IdPayload,
} from "../interfaces/payload";
import { ICalendar } from "../interfaces/calendar";
import { IAppointment } from "../interfaces/appointment";
import { CalendarIntervalFormValues } from "../interfaces/formData";
import {
  BlockDayParam,
  CategoryIdTimezoneParam,
  DispatchParam,
  UpdateCalendarParam,
} from "../interfaces/param";
import { AppError } from "../utils/errors";
import { apiCall } from "../utils/api";

export const deleteCalendar = createAsyncThunk<IdPayload, DocRequest>(
  "calendar/delete",
  async (data: DocRequest) => {
    try {
      await apiCall<any, any>({
        endpoint: "deleteCalendarV2",
        body: { calendarUid: data.id },
      });
      return { id: data.id };
    } catch (error) {
      Logger.error(
        "Calendar ::: deleteCalendar ::: Could not delete calendar",
        error,
        data,
      );
      throw new AppError("Could not delete calendar", error);
    }
  },
);

export const updateCalendar = createAsyncThunk<
  GetCalendarPayload,
  UpdateCalendarParam
>("calendar/updateCalendar", async (data: UpdateCalendarParam) => {
  try {
    const intervals = data.intervals;

    let updateDataBody = {
      title: data.title,
      description: data.description,
      calendarUid: data.calendarUid,
      duration: data.duration,
      categoryUid: data.user.category.categoryUid,
      timezone: data.user.category.timezone,
    };
    if (intervals) {
      const output = Object.fromEntries(
        Object.entries(intervals).map(([day, intervals]) => {
          const formattedIntervals = intervals.map(
            ({ startTime, endTime }: any) => `${startTime}-${endTime}`,
          );
          return [day, formattedIntervals];
        }),
      );
      updateDataBody = {
        ...updateDataBody,
        ...output,
      };
    }
    const response = await apiCall<any, ICalendar>({
      endpoint: "createUpdateCalendarV2",
      body: sanitize(updateDataBody),
    });

    return { calendar: response };
  } catch (error) {
    Logger.error(
      "Calendar ::: updateCalendar ::: Could not update calendar",
      error,
      data,
    );
    throw new AppError("Could not update calendar", error);
  }
});
export const createCalendar = createAsyncThunk<
  GetCalendarPayload,
  CalendarIntervalFormValues
>("calendar/create", async (data: CalendarIntervalFormValues) => {
  try {
    const categoryUid = data.user.category.categoryUid;

    const title = data.title;
    const description = data.description;
    const duration = data.duration;
    const intervals = data.intervals;

    const output = Object.fromEntries(
      Object.entries(intervals).map(([day, intervals]) => {
        const formattedIntervals = intervals.map(
          ({ startTime, endTime }: any) => `${startTime}-${endTime}`,
        );
        return [day, formattedIntervals];
      }),
    );

    const body = {
      title: title,
      description: description,
      duration: duration,
      categoryUid: categoryUid,
      daysOff: [],
      timezone: data.user.category.timezone,
      ...output,
    };
    const response = await apiCall<any, ICalendar>({
      endpoint: "createUpdateCalendarV2",
      body: sanitize(body),
    });
    if (!response) {
      throw new Error("Could not create the calendar");
    }

    return { calendar: response };
  } catch (error) {
    Logger.error(
      "Calendar ::: createCalendar ::: Could not create calendar",
      error,
      data,
    );
    throw new AppError("Could not create calendar", error);
  }
});

export const unblockDayCalendar = createAsyncThunk<void, BlockDayParam>(
  "calendar/unblockDay",
  async (data: BlockDayParam) => {
    try {
      const collectionName = "calendars";
      const collectionRef = collection(db, collectionName);
      const documentRef = doc(collectionRef, data.id);
      const converted = data.daysOff.map((day: Moment) => {
        return day.toDate();
      }) as Date[];

      await updateDoc(documentRef, {
        daysOff: converted,
      });
    } catch (error) {
      Logger.error(
        "Calendar ::: unblockDayCalendar ::: Could not unblock day",
        error,
        data,
      );
      throw new AppError("Could not unblock day", error);
    }
  },
);

export const blockDayCalendar = createAsyncThunk<void, BlockDayParam>(
  "calendar/blockDay",
  async (data: BlockDayParam) => {
    try {
      const collectionName = "calendars";
      const collectionRef = collection(db, collectionName);
      const documentRef = doc(collectionRef, data.id);
      const convertedToDate = data.daysOff.map((day: Moment) => {
        return getConvertedMoment(day);
      });
      await updateDoc(documentRef, {
        daysOff: convertedToDate,
      });
    } catch (error) {
      Logger.error(
        "Calendar ::: blockDayCalendar ::: Could not block day",
        error,
        data,
      );
      throw new AppError("Could not block day", error);
    }
  },
);

export const getCalendars = createAsyncThunk<void, DispatchParam>(
  "turnos/getCalendars",
  async (data: DispatchParam) => {
    try {
      const categoryUid = localStorage.getItem("categoryUid");
      const timezone = localStorage.getItem("timezone") as string;
      const collection_name = "calendars";
      const collection_ref = collection(db, collection_name);
      const q = query(
        collection_ref,
        where("categoryUid", "==", categoryUid),
        where("deleted", "==", false),
      );

      onSnapshot(
        q,
        (snapshot) => {
          const list = getCalendarListFromQuery(snapshot.docs, timezone);
          data.dispatch(fillCalendarList({ calendars: list }));
        },
        (error) => {
          Logger.error(
            "Calendar ::: getCalendars ::: Could not stream calendars",
            error,
          );
          throw new AppError("Could not stream calendars", error);
        },
      );
    } catch (error) {
      Logger.error(
        "Calendar ::: getCalendars ::: Could not get calendars",
        error,
      );
      throw new AppError("Could not get calendars", error);
    }
  },
);

export const getPublicCalendars = createAsyncThunk<
  GetCalendarsPayload,
  CategoryIdTimezoneParam
>("turnos/getPublicCalendars", async (data: CategoryIdTimezoneParam) => {
  try {
    const { categoryId, timezone } = data;
    if (!categoryId) return { calendars: [] };
    const collection_ref = collection(db, "calendars");
    const q = query(
      collection_ref,
      where("categoryUid", "==", categoryId),
      where("deleted", "==", false),
    );
    const snapshot = await getDocs(q);
    const res: ICalendar[] = [];

    snapshot.forEach((doc) => {
      const data = doc.data();
      if (data.deleted) return;
      const item = {
        id: doc.id as string,
        daysOff: getArray(data.daysOff).map((day: Timestamp) => {
          return getMomentFromTimestamp(day, timezone);
        }),
        duration: data.duration as number,
        title: data.title as string,
        description: data.description as string | null,
        monday: getArray(data.monday),
        tuesday: getArray(data.tuesday),
        wednesday: getArray(data.wednesday),
        thursday: getArray(data.thursday),
        friday: getArray(data.friday),
        saturday: getArray(data.saturday),
        sunday: getArray(data.sunday),
      } as ICalendar;

      res.push(item);
    });
    return { calendars: res };
  } catch (error) {
    Logger.error(
      "Calendar ::: getPublicCalendars ::: Could not get calendars public",
      error,
      data,
    );
    throw new AppError("Could not get calendars public", error);
  }
});

export interface CalendarState {
  turnos: [];
  calendars: ICalendar[];
  calendarSelected: ICalendar | null;
  publicCalendars: ICalendar[];
  turnosListVacios: IAppointment[];
  getCalendarsStatus: STATUS;
  createStatus: STATUS;
  deleteStatus: STATUS;
  updateCalendarStatus: STATUS;
  blockStatus: STATUS;
  error: string | null | undefined;
  intervals: IntervalsType;
  intervalsOriginals: IntervalsType;
}

const initialState: CalendarState = {
  turnos: [],
  calendars: [],
  publicCalendars: [],
  calendarSelected: null,
  turnosListVacios: [],
  getCalendarsStatus: STATUS.IDLE,
  createStatus: STATUS.IDLE,
  deleteStatus: STATUS.IDLE,
  updateCalendarStatus: STATUS.IDLE,
  blockStatus: STATUS.IDLE,
  error: null,
  intervals: EMPTY_INTERVALS,
  intervalsOriginals: EMPTY_INTERVALS,
};

export const CalendarsSlice = createSlice({
  name: "calendars",
  initialState: initialState,
  reducers: {
    fillCalendarList: (
      state,
      action: PayloadAction<{ calendars: ICalendar[] }>,
    ) => {
      state.calendars = [...action.payload.calendars];
    },
    selectCalendar: (
      state,
      action: PayloadAction<{ calendarId: string | null }>,
    ) => {
      const { calendarId } = action.payload;
      if (calendarId === null) return;
      if (calendarId === "TODOS") {
        state.calendarSelected = {
          id: calendarId,
          title: calendarId,
        } as ICalendar;
      } else {
        const findCalendar = state.calendars.find(
          (item) => item.id === calendarId,
        ) as ICalendar;
        state.calendarSelected = findCalendar;
        state.intervals = fillAllIntervalsCalendarOnEdit(findCalendar);
        state.intervalsOriginals = fillAllIntervalsCalendarOnEdit(findCalendar);
      }
    },
    clearCalendarSelected: (state) => {
      state.calendarSelected = null;
    },
    unblockDay: (
      state: any,
      action: PayloadAction<{ id: string; daysOff: any }>,
    ) => {
      const calendarId = action.payload.id;
      const daysOff = action.payload.daysOff.map((day: Moment) => {
        return day;
      });
      const calendars = [...state.calendars] as any; // Create a copy of the calendars array
      const calendarIndex = calendars.findIndex(
        (calendar: any) => calendar.id === calendarId,
      ) as number;
      if (calendarIndex !== -1) {
        const updatedCalendar = { ...calendars[calendarIndex], daysOff }; // Create a new calendar object with updated daysOff
        calendars[calendarIndex] = updatedCalendar; // Replace the calendar at the specified index with the updated one
      }
      state.calendars = calendars;
    },
    agregarTurnosListVacios: (state, action) => {
      const temp = [...state.turnosListVacios, ...action.payload] as any;

      temp.sort((a: any, b: any) => {
        return a.time.toDate() - b.time.toDate();
      });

      state.turnosListVacios = temp;
    },

    resetTurnosListVacios: (state) => {
      state.turnosListVacios = [];
    },
    blockDay: (state, action) => {
      const calendarId = action.payload.id;
      const daysOff = action.payload.daysOff;
      const calendars = [...state.calendars] as any; // Create a copy of the calendars array
      const calendarIndex = calendars.findIndex(
        (calendar: any) => calendar.id === calendarId,
      ) as number;
      if (calendarIndex !== -1) {
        const updatedCalendar = { ...calendars[calendarIndex], daysOff }; // Create a new calendar object with updated daysOff
        calendars[calendarIndex] = updatedCalendar; // Replace the calendar at the specified index with the updated one
      }
      state.calendars = calendars;
    },
    resetState: (state) => {
      state.getCalendarsStatus = STATUS.IDLE;
      state.createStatus = STATUS.IDLE;
      state.deleteStatus = STATUS.IDLE;
      state.updateCalendarStatus = STATUS.IDLE;
      state.blockStatus = STATUS.IDLE;
      state.error = null;
    },
    updateIntervals: (
      state,
      action: PayloadAction<{
        props: { day: string; index: number; field: string; newValue: string };
      }>,
    ) => {
      const props = action.payload.props;
      const updatedIntervals = updateIntervalItemFromIntervalDays(
        state.intervals,
        props.day,
        props.index,
        props.field,
        props.newValue,
      );
      state.intervals = updatedIntervals;
    },
    switchAndUpdateInterval: (
      state,
      action: PayloadAction<{
        props: { intervals: IntervalsType; day: string };
      }>,
    ) => {
      const props = action.payload.props;
      const updatedIntervals = updateIntervalFromIntervalDays(
        props.intervals,
        props.day,
      );
      state.intervals = updatedIntervals;
    },
    removeAndUpdateInterval: (
      state,
      action: PayloadAction<{ props: { day: string; index: number } }>,
    ) => {
      const props = action.payload.props;
      const updatedIntervals = removeIntervalFromIntervalDays(
        state.intervals,
        props.day,
        props.index,
      );
      state.intervals = updatedIntervals;
      state.intervalsOriginals = updatedIntervals;
    },
    addAndUpdateInterval: (
      state,
      action: PayloadAction<{ props: { day: string } }>,
    ) => {
      const props = action.payload.props;
      const updatedIntervals = addIntervalIntoIntervalDays(
        state.intervals,
        props.day,
      );
      state.intervals = updatedIntervals;
    },
    resetCalendarState: (state) => {
      state.calendars = [];
      state.publicCalendars = [];
      state.calendarSelected = null;
      state.error = null;
      state.getCalendarsStatus = STATUS.IDLE;
      state.createStatus = STATUS.IDLE;
      state.deleteStatus = STATUS.IDLE;
      state.updateCalendarStatus = STATUS.IDLE;
      state.blockStatus = STATUS.IDLE;
      state.intervals = EMPTY_INTERVALS;
      state.intervalsOriginals = EMPTY_INTERVALS;
    },
    resetOnlyStatus: (state) => {
      state.createStatus = STATUS.IDLE;
      state.updateCalendarStatus = STATUS.IDLE;
      state.deleteStatus = STATUS.IDLE;
    },
    resetIntervals: (state) => {
      state.intervals = EMPTY_INTERVALS;
      state.intervalsOriginals = EMPTY_INTERVALS;
    },
    generateCalendarIntervals: (state) => {
      if (state.calendarSelected) {
        state.intervals = fillAllIntervalsCalendarOnEdit(
          state.calendarSelected,
        );
        state.intervalsOriginals = fillAllIntervalsCalendarOnEdit(
          state.calendarSelected,
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCalendars.pending, (state, action) => {
      Logger.log("Fetching calendars.....");
      state.getCalendarsStatus = STATUS.FETCHING;
    });
    builder.addCase(getCalendars.fulfilled, (state, action) => {
      Logger.log("Fetched calendars.....");
      state.getCalendarsStatus = STATUS.FETCH;
    });
    builder.addCase(getCalendars.rejected, (state, action) => {
      Logger.log("Failed calendars.....");
      state.getCalendarsStatus = STATUS.FETCH_ERROR;
      state.calendars = [];
      state.error = action.error.message as string;
    });
    builder.addCase(getPublicCalendars.pending, (state, action) => {
      Logger.log("Fetching public calendars.....");
      state.getCalendarsStatus = STATUS.FETCHING;
    });
    builder.addCase(getPublicCalendars.fulfilled, (state, action) => {
      Logger.log("Fetched public calendars.....");
      state.getCalendarsStatus = STATUS.FETCH;
      state.publicCalendars = action.payload.calendars ?? [];
    });
    builder.addCase(getPublicCalendars.rejected, (state, action) => {
      Logger.log("Failed public calendars.....");
      state.getCalendarsStatus = STATUS.FETCH_ERROR;
      state.error = action.error.message;
    });
    builder.addCase(createCalendar.pending, (state, action) => {
      Logger.log("Fetching createCalendar.....");
      state.createStatus = STATUS.FETCHING;
    });
    builder.addCase(
      createCalendar.fulfilled,
      (state, action: PayloadAction<{ calendar: ICalendar }>) => {
        Logger.log("Fetched createCalendar.....");
        state.createStatus = STATUS.FETCH;
        state.calendars = state.calendars
          ? [...state.calendars, action.payload.calendar]
          : [];
        state.calendarSelected = action.payload.calendar;
        state.intervals = fillAllIntervalsCalendarOnEdit(
          action.payload.calendar,
        );
      },
    );
    builder.addCase(createCalendar.rejected, (state, action) => {
      Logger.log("Failed createCalendar.....");
      state.createStatus = STATUS.FETCH_ERROR;
      state.error = action.error.message;
    });
    builder.addCase(deleteCalendar.pending, (state, action) => {
      Logger.log("Fetching deleteCalendar.....");
      state.deleteStatus = STATUS.FETCHING;
    });
    builder.addCase(
      deleteCalendar.fulfilled,
      (state, action: PayloadAction<{ id: string }>) => {
        Logger.log("Fetched deleteCalendar.....");
        state.deleteStatus = STATUS.FETCH;
        state.calendars = state.calendars
          ? [...state.calendars.filter((item) => item.id !== action.payload.id)]
          : [];
        state.calendarSelected = null;
      },
    );
    builder.addCase(deleteCalendar.rejected, (state, action) => {
      Logger.log("Failed deleteCalendar.....");
      state.deleteStatus = STATUS.FETCH_ERROR;
      state.error = action.error.message;
    });
    builder.addCase(updateCalendar.pending, (state, action) => {
      Logger.log("Fetching updateCalendar.....");
      state.updateCalendarStatus = STATUS.FETCHING;
    });
    builder.addCase(
      updateCalendar.fulfilled,
      (state, action: PayloadAction<{ calendar: ICalendar }>) => {
        Logger.log("Fetched updateCalendar.....");
        state.calendars = state.calendars
          ? [
              ...state.calendars.map((item) => {
                if (item.id === action.payload.calendar.id) {
                  return {
                    ...item,
                    ...action.payload.calendar,
                  };
                }
                return item;
              }),
            ]
          : [];
        state.intervals = fillAllIntervalsCalendarOnEdit(
          action.payload.calendar,
        );
        state.updateCalendarStatus = STATUS.FETCH;
        state.calendarSelected = action.payload.calendar;
      },
    );
    builder.addCase(updateCalendar.rejected, (state, action) => {
      Logger.log("Failed updateCalendar.....");
      state.updateCalendarStatus = STATUS.FETCH_ERROR;
      state.error = action.error.message;
    });
    builder.addCase(unblockDayCalendar.pending, (state, action) => {
      Logger.log("Fetching unblockDay.....");
      state.blockStatus = STATUS.FETCHING;
    });
    builder.addCase(unblockDayCalendar.fulfilled, (state, action) => {
      Logger.log("Fetched unblockDay.....");
      state.blockStatus = STATUS.FETCH;
    });
    builder.addCase(unblockDayCalendar.rejected, (state, action) => {
      Logger.log("Failed unblockDay.....");
      state.blockStatus = STATUS.FETCH_ERROR;
      state.error = action.error.message;
    });
    builder.addCase(blockDayCalendar.pending, (state, action) => {
      Logger.log("Fetching blockDay.....");
      state.blockStatus = STATUS.FETCHING;
    });
    builder.addCase(blockDayCalendar.fulfilled, (state, action) => {
      Logger.log("Fetched blockDay.....");
      state.blockStatus = STATUS.FETCH;
    });
    builder.addCase(blockDayCalendar.rejected, (state, action) => {
      Logger.log("Failed blockDay.....");
      state.blockStatus = STATUS.FETCH_ERROR;
      state.error = action.error.message;
    });
  },
});

export const {
  unblockDay,
  blockDay,
  resetCalendarState,
  resetOnlyStatus,
  resetIntervals,
  generateCalendarIntervals,
  resetState,
  agregarTurnosListVacios,
  selectCalendar,
  fillCalendarList,
  clearCalendarSelected,
  resetTurnosListVacios,
  updateIntervals,
  switchAndUpdateInterval,
  removeAndUpdateInterval,
  addAndUpdateInterval,
} = CalendarsSlice.actions;

export default CalendarsSlice.reducer;
