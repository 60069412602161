import { IAddress } from "../interfaces/address";
import {
  CategoryEditFormValues,
  IFormErrors,
  IFormValues,
  IFormValuesRequired,
} from "../interfaces/formData";
import { emailRegex } from "./constants";

export const validateForm = (
  formValues: IFormValues | IFormValuesRequired,
  validCaptcha: boolean,
): IFormErrors => {
  const errors: IFormErrors = {};
  Object.entries(formValues).forEach(([key, value]) => {
    if (key === "mobileNumber" && isNaN(value)) {
      errors[key] = "El teléfono no es válido";
    } else if (key === "email" && !emailRegex.test(value)) {
      errors[key] = "El email no es válido";
    } else if (!value) {
      errors[key] = "Este campo es requerido";
    } else if (!validCaptcha) {
      errors["captcha"] = "Debe completar el captcha";
    }
  });
  return errors;
};

export const validateCategoryEditForm = (
  formData: CategoryEditFormValues,
): IFormErrors => {
  const errors: IFormErrors = {};

  for (const key in formData) {
    if (!formData[key as keyof CategoryEditFormValues]) {
      errors[key] = "Campo requerido";
    }
  }

  if (!formData.businessEmail) {
    errors.businessEmail = "Email es requerido";
  } else if (!/\S+@\S+\.\S+/.test(formData.businessEmail)) {
    errors.businessEmail = "Email es invalido";
  }

  if (!addressValidated(formData.address)) {
    errors.address = "Dirección es requerida";
  }

  return errors;
};

export const validateAnyForm = (errors: IFormErrors): boolean => {
  return Object.keys(errors).length === 0;
};

export const addressValidated = (address?: IAddress | null): boolean => {
  return (
    !address ||
    address.route !== "" ||
    address.locality !== "" ||
    address.country !== "" ||
    address.fullAddress !== ""
  );
};

export const emailIsValid = (email?: string | null): boolean => {
  return email !== undefined && email !== null && emailRegex.test(email);
};

export const validateAnyFormValues = <T>(formData: T): IFormErrors => {
  const errors: IFormErrors = {};

  for (const key in formData) {
    if (
      formData[key as keyof T] === null ||
      formData[key as keyof T] === undefined ||
      formData[key as keyof T] === ""
    ) {
      errors[key] = "Campo requerido";
    } else if (
      key === "address" &&
      !addressValidated(formData[key as keyof T] as unknown as IAddress)
    ) {
      errors[key] = "Dirección es requerida";
    } else if (
      key === "email" &&
      !emailIsValid(formData[key as keyof T] as unknown as string)
    ) {
      errors[key] = "Email es invalido";
    }
  }
  return errors;
};
export const validateEmail = (email?: string | null): boolean => {
  if (!email) {
    return false;
  }
  // Simple regex for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
