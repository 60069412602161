import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  deleteDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { db, storage } from "../service/firebase";
import Logger from "../utils/Logger";
import { STATUS } from "../utils/constants";
import {
  filterMedicalRecords,
  updateAllMedicalRecords,
  sanitize,
  getMomentFromTimestamp,
  sortRecordItems,
  getTodayFromTimezone,
  fixEspecie,
} from "../utils/functions";
import { CreateRecordPayload } from "../interfaces/payload";
import {
  AddRecordParam,
  CreateRecordParam,
  DeleteRecordParam,
  GetMedicalRecordsParam,
  UpdateRecordParam,
} from "../interfaces/param";
import { IRecordItem } from "../interfaces/recordItem";
import { IAppointment } from "../interfaces/appointment";
import { IVaccine } from "../interfaces/vaccine";
import { AddRecordFormValues } from "../interfaces/formData";
import { IMedicalRecord } from "../interfaces/medicalRecord";
import { IReminder } from "../interfaces/reminder";
import { AppError } from "../utils/errors";
import { emailIsValid } from "../utils/validations";
import { IPetOwner } from "../interfaces/petOwner";

export const getMedicalRecords = createAsyncThunk<
  GetMedicalRecordsParam,
  string
>("medicalRecords/get", async (categoryUid: string) => {
  let uid;
  try {
    const collectionPetOwners = collection(db, "petOwners");
    const queryPetOwners = query(
      collectionPetOwners,
      where("categoryUid", "==", categoryUid),
    );
    const snapshotPetOwners = await getDocs(queryPetOwners);
    const resultPetOwners: IPetOwner[] = [];

    snapshotPetOwners.forEach((doc) => {
      const data = doc.data();
      const item = {
        uid: doc.id as string,
        userName: data.userName,
        email: data.email,
        mobileNumber: data.mobileNumber,
        address: data.address,
        createdAt: getMomentFromTimestamp(data.createdAt),
        categoryUid: data.categoryUid,
      } as IPetOwner;
      resultPetOwners.push(item);
    });

    const records: IMedicalRecord[] = [];
    const collection_name = "medicalRecord";
    const collection_ref = collection(db, collection_name);
    const q = query(
      collection_ref,
      where("categoryUid", "==", categoryUid),
      where("deleted", "==", false),
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach((doc) => {
      const recData = doc.data();
      const petOwner = resultPetOwners.find(
        (owner) => owner.uid === recData.petOwnerUid,
      );
      uid = doc.id;
      const recordsFromDoc =
        recData.records && Array.isArray(recData.records)
          ? (recData.records.map((i: any) => {
              return {
                description: i.description || "",
                vaccines:
                  i.vaccines && Array.isArray(i.vaccines)
                    ? i.vaccines.map((e: any) => {
                        return {
                          title: e.title || "",
                          reminder: getMomentFromTimestamp(e.reminder),
                          reminderUid: e.reminderUid,
                        } as IVaccine;
                      })
                    : [],
                reminders:
                  i.reminders && Array.isArray(i.reminders)
                    ? i.reminders.map((e: any) => {
                        return {
                          text: e.text || e.title || "",
                          date: getMomentFromTimestamp(e.date || e.reminder),
                          uid: e.uid || e.reminderUid,
                          type: e.type || "reminder",
                        } as IReminder;
                      })
                    : [],
                files: i.files || [],
                date: getMomentFromTimestamp(i.date),
              } as IRecordItem;
            }) as IRecordItem[])
          : [];
      const sortRecordsByDate = recordsFromDoc.sort((a, b) =>
        b.date && a.date ? b.date?.valueOf() - a.date?.valueOf() : 0,
      );
      const item = {
        uid: doc.id as string,
        userName: petOwner?.userName ?? recData.userName ?? "",
        userUid: petOwner?.userUid ?? recData.userUid ?? "",
        categoryTitle: recData.categoryTitle || "",
        gender: recData.gender || recData.sexo || "Macho",
        dob: getMomentFromTimestamp(recData.dob),
        especie: fixEspecie(recData.especie),
        raza: recData.raza,
        petName: recData.petName || "",
        petOwnerUid: petOwner?.uid || null,
        petOwner: petOwner || null,
        records: sortRecordsByDate,
        observations: recData.observations || "",
        email: petOwner?.email ?? recData.email ?? "",
        mobileNumber: petOwner?.mobileNumber ?? recData.mobileNumber ?? "",
        address: petOwner?.address ?? recData.address ?? "",
        castrado: recData.castrado || false,
      } as IMedicalRecord;
      records.push(item);
    });

    records.forEach((record) => {
      sortRecordItems(record.records);
    });

    return { medicalRecords: records, petOwners: resultPetOwners };
  } catch (error) {
    Logger.error(
      `MedicalRecord ::: getMedicalRecords ::: Could not get medical records on uid: ${uid}`,
      error,
    );
    throw new AppError("Could not get medical records", error);
  }
});

export const createRecord = createAsyncThunk<
  CreateRecordPayload,
  CreateRecordParam
>("medicalRecords/create", async (data: CreateRecordParam, thunkAPI) => {
  try {
    const collectionName = "medicalRecord";
    const collectionRef = collection(db, collectionName);
    const { record, appointment } = data;
    const timezone = localStorage.getItem("timezone");
    const dateFixed = record.dob?.isValid()
      ? record.dob
      : getTodayFromTimezone(timezone);

    const body = {
      userUid: record.userUid,
      gender: record.gender,
      especie: record.especie,
      petOwnerUid: record.petOwnerUid ?? null,
      raza: record.raza,
      dob: dateFixed,
      petName: record.petName,
      castrado: record.castrado,
      records: record.records,
      observations: record.observations,
      categoryUid: data.user.category.categoryUid,
      categoryType: localStorage.getItem("categoryType"),
      categoryTitle: localStorage.getItem("categoryTitle"),
      createdAt: Timestamp.now(),
      deleted: false,
    };

    let petOwner: IPetOwner | null = null;
    if (!record.petOwnerUid) {
      const collectionRefPetWoners = collection(db, "petOwners");
      const bodyPetOwner = {
        userName: record.userName ?? null,
        email: record.email!.toLowerCase(),
        mobileNumber: record.mobileNumber ?? null,
        address: record.address ?? null,
        createdAt: Timestamp.now(),
        categoryUid: data.user.category.categoryUid,
      };
      const responsePetWoners = await addDoc(
        collectionRefPetWoners,
        sanitize(bodyPetOwner),
      );
      petOwner = {
        ...bodyPetOwner,
        uid: responsePetWoners.id,
        createdAt: null,
      };
      body.petOwnerUid = responsePetWoners.id;
    } else {
      const collectionRefPetOwners = collection(db, "petOwners");
      const documentRefPetOwners = doc(
        collectionRefPetOwners,
        record.petOwnerUid,
      );
      const bodyPetOwner = {
        userName: record.userName ?? null,
        email: record.email!.toLowerCase(),
        mobileNumber: record.mobileNumber ?? null,
        address: record.address ?? null,
      };
      await updateDoc(documentRefPetOwners, sanitize(bodyPetOwner));
      petOwner = {
        ...bodyPetOwner,
        uid: record.petOwnerUid,
        categoryUid: data.user.category.categoryUid,
        createdAt: null,
      };
      body.petOwnerUid = record.petOwnerUid;
    }

    const response = await addDoc(collectionRef, sanitize(body));
    const item = {
      ...body,
      petOwner,
      uid: response.id,
      userName: record.userName,
      email: record.email?.toLowerCase(),
      mobileNumber: record.mobileNumber,
      address: record.address,
    } as IMedicalRecord;
    if (appointment) {
      const collectionName2 = "appointments";
      const collectionRef2 = collection(db, collectionName2);
      const documentRef2 = doc(collectionRef2, appointment.id);
      await updateDoc(documentRef2, {
        medicalRecordUid: response.id,
      });
    }

    return { item };
  } catch (error) {
    Logger.error(
      "MedicalRecord ::: createRecord ::: Could not create medical record",
      error,
      data,
    );
    throw new AppError(
      "MedicalRecord ::: createRecord ::: Could not create medical record",
      error,
    );
  }
});

// export const createRecordAndAssignAppoinment = createAsyncThunk<CreateRecordPayload, void>(
//   "medicalRecords/createRecordAndAssignAppoinment",
//   async (data: any) => {
//     try {
//       const { medicalRecord, turnoSelected } = data;
//       const collectionName = "medicalRecord";
//       const collectionRef = collection(db, collectionName);
//       const body = {
//         userName: medicalRecord.userName,
//         userUid: medicalRecord.userUid,
//         gender: medicalRecord.gender,
//         especie: medicalRecord.especie,
//         raza: medicalRecord.raza,
//         dob: medicalRecord.dob,
//         petName: medicalRecord.petName,
//         address: medicalRecord.address,
//         observations: medicalRecord.observations,
//         email: medicalRecord.email,
//         mobileNumber: medicalRecord.mobileNumber,
//         castrado: medicalRecord.castrado,
//         records: medicalRecord.records,
//         categoryUid: localStorage.getItem("categoryUid"),
//         categoryType: localStorage.getItem("categoryType"),
//         categoryTitle: localStorage.getItem("categoryTitle"),
//         createdAt: Timestamp.now(),
//       };
//       const response = await addDoc(collectionRef, sanitize(body));
//       const collectionName2 = "appointments";
//       const collectionRef2 = collection(db, collectionName2);
//       const documentRef2 = doc(collectionRef2, turnoSelected.id);
//       await updateDoc(documentRef2, {
//         medicalRecordUid: response.id,
//       });
//       let item = {
//         ...body,
//         uid: response.id,
//       } as IMedicalRecord;

//       return { item };
//     } catch (error) {
//         Logger.error("Could not create medical record", error);
//         throw new AppError("Could not create medical record", error);
//     }
//   }
// );

// export const uploadMedicalDocument = createAsyncThunk(
//   "medicalRecords/uploadMedicalDocument",
//   async (data: any) => {
//     try {
//       const { file, categoryUid, categoryType, medicalRecordSelected } = data;
//       const storageRef = ref(
//         storage,
//         `/categories/${categoryType.replace(
//           " ",
//           "_"
//         )}/${categoryUid}/medicalRecords/${file.name
//           .replace(" ", "_")
//           .toLowerCase()}`
//       );

//       await uploadBytes(storageRef, data.file);
//       const url = await getDownloadURL(storageRef);
//       const newRecord = {
//         description: url,
//         date: Timestamp.fromDate(getNowMomentFromDatetime(timezone).toDate()),
//       };
//       const newRecors = [...medicalRecordSelected.records, newRecord];

//       const collectionName = "medicalRecord";
//       const collectionRef = collection(db, collectionName);
//       const documentRef = doc(collectionRef, medicalRecordSelected.uid);
//       await updateDoc(documentRef, {
//         records: newRecors,
//       });
//       const newMedicalRecord = { ...medicalRecordSelected, records: newRecors };
//       return newMedicalRecord;
//     } catch (error) {
//       Logger.error("Could not upload document", error);
//       throw new AppError("Could not upload document", error);
//     }
//   }
// );

// export const uploadDocumentUrl = createAsyncThunk(
//   "medicalRecords/uploadDocumentUrl",
//   async (data: any) => {
//     const uniqueId = uuidv4();
//     try {
//       const { files, categoryUid, medicalRecord } = data;
//       var urls = [];

//       for (let index = 0; index < files.length; index++) {
//         const storageRef = ref(
//           storage,
//           `/medicalRecords/${categoryUid}/${medicalRecord.uid}/${uniqueId}_##${files[index].name}`
//         );

//         await uploadBytes(storageRef, files[index]);
//         const url = await getDownloadURL(storageRef);
//         urls.push(url);
//       }

//       return urls;
//     } catch (error) {
//       Logger.error("Could not upload document", error);
//       return false;
//     }
//   }
// );

export const deleteRecord = createAsyncThunk<IMedicalRecord, DeleteRecordParam>(
  "medicalRecords/delete",
  async (data: DeleteRecordParam) => {
    try {
      const { medicalRecord, record } = data;
      const collectionName = "medicalRecord";
      const collectionRef = collection(db, collectionName);

      if (!medicalRecord.uid) throw new Error("Medical record uid is null");

      const recordsToSave = medicalRecord.records.filter(
        (r) => r.date !== record.date,
      );
      if (record.reminders && record.reminders.length > 0) {
        for (const reminder of record.reminders) {
          if (!reminder.uid) continue;
          const collectionNameReminder = "reminders";
          const deleteRef = doc(db, collectionNameReminder, reminder.uid);
          await deleteDoc(deleteRef);
        }
      }
      if (record.vaccines && record.vaccines.length > 0) {
        for (const vaccine of record.vaccines) {
          if (!vaccine.reminderUid) continue;
          const collectionNameReminder = "reminders";
          const deleteRef = doc(
            db,
            collectionNameReminder,
            vaccine.reminderUid,
          );
          await deleteDoc(deleteRef);
        }
      }
      if (record.files && record.files.length > 0) {
        for (const file of record.files) {
          const storageRef = ref(storage, file);
          await deleteObject(storageRef);
        }
      }

      const documentRef = doc(collectionRef, medicalRecord.uid);
      const body = {
        records: recordsToSave,
      };
      await updateDoc(documentRef, sanitize(body));
      const medicalRecordEdited = {
        ...medicalRecord,
        records: recordsToSave,
      } as IMedicalRecord;
      return medicalRecordEdited;
    } catch (error) {
      Logger.error(
        "MedicalRecord ::: deleteRecord ::: Could not delete medical record",
        error,
        data,
      );
      throw new AppError("Could not delete medical record", error);
    }
  },
);

export const addRecord = createAsyncThunk<IMedicalRecord, AddRecordParam>(
  "medicalRecords/add",
  async (data: AddRecordParam) => {
    try {
      const { categoryUid } = data.user.category;
      const { medicalRecord, newRecord, newFiles } = data;
      const collectionName = "medicalRecord";
      const collectionRef = collection(db, collectionName);
      const collectionNameReminder = "reminders";
      const collectionRefReminder = collection(db, collectionNameReminder);
      if (!medicalRecord.uid) throw new Error("Medical record uid is null");

      let newRecordToAdd = {
        description: newRecord.description,
        date: newRecord.date,
      } as AddRecordFormValues;

      if (newRecord.reminders && newRecord.reminders.length > 0) {
        for (const reminder of newRecord.reminders) {
          const reminderBody = {
            title: reminder.text,
            reminder: reminder.date,
            userName: medicalRecord.userName,
            categoryTitle: medicalRecord.categoryTitle,
            petName: medicalRecord.petName,
            email: medicalRecord.email?.toLowerCase(),
            mobileNumber: medicalRecord.mobileNumber,
            type: "reminder",
            categoryUid: categoryUid,
            medicalRecordUid: medicalRecord.uid,
            createdAt: Timestamp.now(),
          };
          const refResponse = await addDoc(
            collectionRefReminder,
            sanitize(reminderBody),
          );
          newRecordToAdd = {
            ...newRecordToAdd,
            reminders: newRecordToAdd.reminders
              ? [
                  ...newRecordToAdd.reminders,
                  { ...reminder, uid: refResponse.id },
                ]
              : [{ ...reminder, uid: refResponse.id }],
          };
        }
      }

      if (newRecord.vaccines && newRecord.vaccines.length > 0) {
        for (const vaccine of newRecord.vaccines) {
          const reminder = {
            title: vaccine.title,
            reminder: vaccine.reminder,
            petName: medicalRecord.petName,
            userName: medicalRecord.userName || "",
            email: medicalRecord.email?.toLowerCase() || "",
            mobileNumber: medicalRecord.mobileNumber || "",
            type: "vaccine",
            categoryUid: categoryUid,
            medicalRecordUid: medicalRecord.uid,
            createdAt: Timestamp.now(),
          };
          const refResponse = await addDoc(
            collectionRefReminder,
            sanitize(reminder),
          );
          newRecordToAdd = {
            ...newRecordToAdd,
            vaccines: newRecordToAdd.vaccines
              ? [
                  ...newRecordToAdd.vaccines,
                  { ...vaccine, reminderUid: refResponse.id },
                ]
              : [{ ...vaccine, reminderUid: refResponse.id }],
          };
        }
      }
      if (newFiles && newFiles.length > 0) {
        const uniqueId = uuidv4();
        const urls = [];

        for (let index = 0; index < newFiles.length; index++) {
          const storageRef = ref(
            storage,
            `/medicalRecords/${categoryUid}/${medicalRecord.uid}/${uniqueId}_##${newFiles[index].name}`,
          );

          await uploadBytes(storageRef, newFiles[index]);
          const url = await getDownloadURL(storageRef);
          urls.push(url);
        }
        newRecordToAdd = {
          ...newRecordToAdd,
          files: urls,
        };
      }
      const records = medicalRecord.records
        ? [...medicalRecord.records, newRecordToAdd]
        : [newRecordToAdd];
      const sortedRecords = sortRecordItems(records);
      const documentRef = doc(collectionRef, medicalRecord.uid);

      const body = {
        userName: medicalRecord.userName || "",
        userUid: medicalRecord.userUid || "",
        gender: medicalRecord.gender || "Macho",
        especie: medicalRecord.especie,
        raza: medicalRecord.raza,
        dob: medicalRecord.dob,
        petName: medicalRecord.petName || "",
        records: sortedRecords,
        observations: medicalRecord.observations || "",
        email: medicalRecord.email?.toLowerCase() || "",
        mobileNumber: medicalRecord.mobileNumber || "",
        address: medicalRecord.address || "",
        castrado: medicalRecord.castrado || false,
      };
      await updateDoc(documentRef, sanitize(body));
      const medicalRecordEdited = {
        ...medicalRecord,
        records: sortedRecords,
      } as IMedicalRecord;
      return medicalRecordEdited;
    } catch (error) {
      Logger.error(
        "MedicalRecord ::: addRecord ::: Could not add medical record",
        error,
        data,
      );
      throw new AppError("Could not add medical record", error);
    }
  },
);

export const deleteMedicalRecord = createAsyncThunk(
  "medicalRecords/deleteMedicalRecord",
  async (data: IMedicalRecord) => {
    try {
      const collectionName = "medicalRecord";
      const collectionRef = collection(db, collectionName);
      const documentRef = doc(collectionRef, data.uid);
      await updateDoc(documentRef, {
        deleted: true,
        deletedAt: Timestamp.now(),
      });
      return data;
    } catch (error) {
      Logger.error(
        "MedicalRecord ::: deleteMedicalRecord ::: Could not delete medical record",
        error,
        data,
      );
      throw new AppError("Could not delete medical record", error);
    }
  },
);

export const updateRecord = createAsyncThunk(
  "medicalRecords/update",
  async (data: UpdateRecordParam, thunkAPI) => {
    try {
      const {
        medicalRecord,
        medicalRecordEdited,
        addData,
        deleteData,
        recordToUpdate,
        user,
      } = data;

      const allMedicalRecords = (thunkAPI.getState() as any)
        .medicalRecordsReducers.allRecords as IMedicalRecord[];
      let result: IMedicalRecord = {} as IMedicalRecord;
      const collectionName = "medicalRecord";
      const collectionRef = collection(db, collectionName);
      const collectionNameReminder = "reminders";
      const collectionRefReminder = collection(db, collectionNameReminder);
      if (!medicalRecord.uid) return;
      let dataPrepare = { ...medicalRecordEdited };
      if (recordToUpdate) {
        let recordUpdation = {
          ...recordToUpdate,
        };
        if (addData && addData.description) {
          recordUpdation.description = addData.description;
        }
        // Delete vaccines
        if (
          deleteData &&
          deleteData.vaccines &&
          deleteData.vaccines.length > 0
        ) {
          for (const vaccine of deleteData.vaccines) {
            if (vaccine.reminderUid) {
              const deleteRef = doc(
                db,
                collectionNameReminder,
                vaccine.reminderUid,
              );
              await deleteDoc(deleteRef);
            }
            if (recordUpdation.vaccines) {
              recordUpdation.vaccines = recordUpdation.vaccines.filter(
                (v) =>
                  v.reminderUid !== vaccine.reminderUid &&
                  v.title !== vaccine.title,
              );
            }
          }
        }

        // Delete reminder
        if (
          deleteData &&
          deleteData.reminders &&
          deleteData.reminders.length > 0
        ) {
          for (const reminder of deleteData.reminders) {
            if (reminder.uid) {
              const deleteRef = doc(db, collectionNameReminder, reminder.uid);
              await deleteDoc(deleteRef);
            }
            if (recordUpdation.reminders) {
              recordUpdation.reminders = recordUpdation.reminders.filter(
                (v) => v.uid !== reminder.uid && v.text !== reminder.text,
              );
            }
          }
        }

        // Delete files
        if (deleteData && deleteData.files && deleteData.files.length > 0) {
          for (const file of deleteData.files) {
            const storageRef = ref(storage, file);
            await deleteObject(storageRef);
            if (recordUpdation.files) {
              recordUpdation.files = recordUpdation.files.filter(
                (f) => f !== file,
              );
            }
          }
        }

        // Add vaccines
        if (addData && addData.vaccines && addData.vaccines.length > 0) {
          for (const vaccine of addData.vaccines) {
            const reminder = {
              title: vaccine.title,
              reminder: vaccine.reminder,
              petName: medicalRecord.petName,
              email: medicalRecord.email?.toLowerCase() || "",
              userName: medicalRecord.userName || "",
              mobileNumber: medicalRecord.mobileNumber || "",
              type: "vaccine",
              categoryUid: user.category.categoryUid,
              medicalRecordUid: medicalRecord.uid,
              createdAt: Timestamp.now(),
            };
            const refResponse = await addDoc(
              collectionRefReminder,
              sanitize(reminder),
            );
            recordUpdation = {
              ...recordUpdation,
              vaccines: recordUpdation.vaccines
                ? [
                    ...recordUpdation.vaccines,
                    { ...vaccine, reminderUid: refResponse.id },
                  ]
                : [{ ...vaccine, reminderUid: refResponse.id }],
            };
          }
        }

        // Add reminder
        if (addData && addData.reminders && addData.reminders.length > 0) {
          for (const reminder of addData.reminders) {
            const reminderBody = {
              title: reminder.text,
              reminder: reminder.date,
              userName: medicalRecord.userName,
              categoryTitle: medicalRecord.categoryTitle,
              petName: medicalRecord.petName,
              email: medicalRecord.email?.toLowerCase() || "",
              mobileNumber: medicalRecord.mobileNumber || "",
              type: "reminder",
              categoryUid: user.category.categoryUid,
              medicalRecordUid: medicalRecord.uid,
              createdAt: Timestamp.now(),
            };
            const refResponse = await addDoc(
              collectionRefReminder,
              sanitize(reminderBody),
            );
            recordUpdation = {
              ...recordUpdation,
              reminders: recordUpdation.reminders
                ? [
                    ...recordUpdation.reminders,
                    { ...reminder, uid: refResponse.id },
                  ]
                : [{ ...reminder, uid: refResponse.id }],
            };
          }
        }

        // Add files
        if (addData && addData.files && addData.files.length > 0) {
          const uniqueId = uuidv4();
          const urls = [];

          for (let index = 0; index < addData.files.length; index++) {
            const storageRef = ref(
              storage,
              `/medicalRecords/${user.category.categoryUid}/${medicalRecord.uid}/${uniqueId}_##${addData.files[index].name}`,
            );
            const fileConverted = addData.files[index] as any;
            if (!fileConverted) continue;
            await uploadBytes(storageRef, fileConverted);
            const url = await getDownloadURL(storageRef);
            urls.push(url);
          }
          recordUpdation.files = recordUpdation.files
            ? [...recordUpdation.files, ...urls]
            : [...urls];
        }
        const recordsUpdated = medicalRecord.records?.map((r) => {
          if (
            r.date === recordToUpdate.date &&
            r.description === recordToUpdate.description &&
            r.files?.toString() === recordToUpdate.files?.toString() &&
            r.reminders?.toString() === recordToUpdate.reminders?.toString() &&
            r.vaccines?.toString() === recordToUpdate.vaccines?.toString()
          ) {
            return recordUpdation;
          }
          return r;
        });
        dataPrepare = {
          records: recordsUpdated,
        };
      }

      const sortedRecords = sortRecordItems(dataPrepare.records);
      const documentRef = doc(collectionRef, medicalRecord.uid);
      const body = {
        ...medicalRecord,
        ...medicalRecordEdited,
        records: sortedRecords,
      } as IMedicalRecord;
      result = {
        ...body,
      };
      // If medical record has no pet owner we need to create this pet owner
      // If this case we need to create new pet owner and unlink the old one
      if (
        (!medicalRecord.petOwnerUid && !medicalRecordEdited?.petOwnerUid) ||
        (!medicalRecordEdited?.petOwnerUid && medicalRecord.petOwnerUid)
      ) {
        const collectionPetOwners = "petOwners";
        const collectionRefPetOwners = collection(db, collectionPetOwners);
        const bodyPetOwner = {
          userName: body.userName,
          email: body.email?.toLowerCase(),
          mobileNumber: body.mobileNumber,
          address: body.address,
          createdAt: Timestamp.now(),
          categoryUid: data.user.category.categoryUid,
        };
        const responsePetOwners = await addDoc(
          collectionRefPetOwners,
          sanitize(bodyPetOwner),
        );
        body.petOwnerUid = responsePetOwners.id;
        result = {
          ...result,
          petOwnerUid: responsePetOwners.id,
          petOwner: {
            uid: responsePetOwners.id,
            userName: body.userName,
            email: body.email?.toLowerCase(),
            mobileNumber: body.mobileNumber,
            address: body.address,
            categoryUid: data.user.category.categoryUid,
          },
        };
      } else {
        if (!medicalRecordEdited?.petOwnerUid) {
          throw new AppError(
            "MedicalRecord ::: updateRecord ::: Could not update medical record pet owner uid null",
          );
        }
        const findPetOwner = allMedicalRecords.find(
          (item) => item.petOwnerUid === medicalRecordEdited.petOwnerUid,
        )!;
        // If the medical record has a pet owner we need to update the pet owner
        const hasPetOwnerChangesToSave =
          body.email !== findPetOwner.email ||
          body.mobileNumber !== findPetOwner.mobileNumber ||
          body.address !== findPetOwner.address ||
          body.userName !== findPetOwner.userName;
        if (hasPetOwnerChangesToSave) {
          const collectionRefPetOwners = collection(db, "petOwners");
          const documentRefPetOwners = doc(
            collectionRefPetOwners,
            findPetOwner.petOwnerUid!,
          );
          const bodyPetOwner = {
            userName: body.userName,
            email: body.email?.toLowerCase(),
            mobileNumber: body.mobileNumber,
            address: body.address,
          };
          result.petOwner = {
            ...findPetOwner.petOwner!,
            userName: body.userName,
            email: body.email?.toLowerCase(),
            mobileNumber: body.mobileNumber,
            address: body.address,
          };
          await updateDoc(documentRefPetOwners, sanitize(bodyPetOwner));
        }
      }
      await updateDoc(documentRef, sanitize(body));

      return result;
    } catch (error) {
      Logger.error(
        "MedicalRecord ::: updateRecord ::: Could not update medical record",
        error,
        data,
      );
      throw new AppError("Could not update medical record", error);
    }
  },
);

const initialState: MedicalRecordState = {
  records: [],
  allRecords: [],
  petOwnersFromMedicalRecord: [],
  getMedicalRecordsStatus: STATUS.IDLE,
  addStatus: STATUS.IDLE,
  updateStatus: STATUS.IDLE,
  deleteMedicalRecordStatus: STATUS.IDLE,
  deleteStatus: STATUS.IDLE,
  createStatus: STATUS.IDLE,
  createAndAssignStatus: STATUS.IDLE,
  medicalRecordSelected: null,
  medicalRecordSelectedFromEmail: null,
  appointmentSelected: null,
  uploadDocumentStatus: STATUS.IDLE,
  emailSearcherOptions: [],
  searchQuery: "",
  petOwners: [],
  medicalRecordsOptionsSelector: [],
};

export interface MedicalRecordState {
  records: IRecordItem[];
  allRecords: IMedicalRecord[];
  getMedicalRecordsStatus: STATUS;
  addStatus: STATUS;
  updateStatus: STATUS;
  deleteMedicalRecordStatus: STATUS;
  deleteStatus: STATUS;
  createStatus: STATUS;
  createAndAssignStatus: STATUS;
  appointmentSelected: IAppointment | null;
  medicalRecordSelected: IMedicalRecord | null;
  petOwnersFromMedicalRecord: {
    id: string;
    options: {
      [key: string]: string;
    };
  }[];
  medicalRecordSelectedFromEmail: IMedicalRecord | null;
  uploadDocumentStatus: STATUS;
  emailSearcherOptions: IMedicalRecord[];
  searchQuery: "";
  petOwners: IPetOwner[];
  medicalRecordsOptionsSelector: {
    id: string;
    options: {
      [key: string]: string;
    };
  }[];
}

export const MedicalRecordsSlice = createSlice({
  name: "medicalRecords",
  initialState: initialState,
  reducers: {
    selectMedicalRecordFromUid: (state, action: PayloadAction<string>) => {
      const medicalRecord = state.allRecords.find(
        (record) => record.uid === action.payload,
      );
      state.medicalRecordSelected = medicalRecord!;
      state.records = sortRecordItems(medicalRecord?.records);
      if (medicalRecord?.petOwnerUid) {
        const allMedicalRecordsFromThisPetOwner = state.allRecords.filter(
          (record) => record.petOwnerUid === medicalRecord.petOwnerUid,
        );
        const pets = allMedicalRecordsFromThisPetOwner.map((item) => {
          return {
            id: item.uid,
            options: {
              petName: item.petName,
            },
          };
        });
        state.petOwnersFromMedicalRecord = pets;
      } else {
        state.petOwnersFromMedicalRecord = [];
      }
    },
    selectMedicalRecord: (state, action: PayloadAction<IMedicalRecord>) => {
      state.medicalRecordSelected = action.payload;
      state.records = sortRecordItems(action.payload.records);
      if (state.medicalRecordSelected.petOwnerUid) {
        const allMedicalRecordsFromThisPetOwner = state.allRecords.filter(
          (record) =>
            record.petOwnerUid === state.medicalRecordSelected!.petOwnerUid,
        );
        const pets = allMedicalRecordsFromThisPetOwner.map((item) => {
          return {
            id: item.uid,
            options: {
              petName: item.petName,
            },
          };
        });
        state.petOwnersFromMedicalRecord = pets;
      } else {
        state.petOwnersFromMedicalRecord = [];
      }
    },
    selectAppointment: (state, action) => {
      state.appointmentSelected = action.payload;
      state.records = [];
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    resetStatus: (state) => {
      state.getMedicalRecordsStatus = STATUS.IDLE;
      state.addStatus = STATUS.IDLE;
      state.updateStatus = STATUS.IDLE;
      state.deleteStatus = STATUS.IDLE;
      state.createStatus = STATUS.IDLE;
      state.uploadDocumentStatus = STATUS.IDLE;
      state.createAndAssignStatus = STATUS.IDLE;
    },
    resetCreationStatus: (state) => {
      state.addStatus = STATUS.IDLE;
      state.updateStatus = STATUS.IDLE;
      state.deleteStatus = STATUS.IDLE;
      state.createStatus = STATUS.IDLE;
      state.uploadDocumentStatus = STATUS.IDLE;
      state.createAndAssignStatus = STATUS.IDLE;
    },
    clearMedicalRecordState: (state) => {
      state.appointmentSelected = null;
      state.medicalRecordSelected = null;
      state.records = [];
    },
    resetMedicalRecordState: (state) => {
      state.allRecords = [];
      state.records = [];
      state.getMedicalRecordsStatus = STATUS.IDLE;
      state.addStatus = STATUS.IDLE;
      state.updateStatus = STATUS.IDLE;
      state.deleteStatus = STATUS.IDLE;
      state.createStatus = STATUS.IDLE;
      state.uploadDocumentStatus = STATUS.IDLE;
      state.createAndAssignStatus = STATUS.IDLE;
      state.medicalRecordSelected = null;
      state.appointmentSelected = null;
      state.searchQuery = "";
    },
    searchEmailsFromMedicalRecords: (state, action) => {
      const searchValue = action.payload;
      const filteredRecords = state.allRecords.filter((record) => {
        return (
          emailIsValid(record.email) &&
          record.email.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
      const emailMap = new Map<string, any[]>();
      filteredRecords.forEach((record) => {
        const email = record.email.toLowerCase();
        if (!emailMap.has(email)) {
          emailMap.set(email, []);
        }
        emailMap.get(email)!.push(record);
      });
      const result: IMedicalRecord[] = [];
      emailMap.forEach((records) => {
        if (records.length > 1) {
          const withPetOwnerUid = records.find((record) => record.petOwnerUid);
          if (withPetOwnerUid) {
            result.push(withPetOwnerUid);
          } else {
            result.push(...records);
          }
        } else {
          result.push(...records);
        }
      });

      state.emailSearcherOptions = result.map((record) => record);
    },
    selectMedicalRecordFromEmailAndCopyInformation: (state, action) => {
      const emailFromMedicalRecordSelected = action.payload as IMedicalRecord;

      if (emailFromMedicalRecordSelected) {
        state.medicalRecordSelectedFromEmail = {
          ...emailFromMedicalRecordSelected,
          petName: "",
          petUid: null,
          especie: "",
          raza: "",
          dob: null,
          records: [],
          gender: "",
        };
      }
      state.emailSearcherOptions = [];
    },
    resetMedicalRecord: (state) => {
      state.medicalRecordSelected = null;
      state.medicalRecordSelectedFromEmail = null;
      state.emailSearcherOptions = [];
      state.appointmentSelected = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMedicalRecords.pending, (state, action) => {
      Logger.log("Fetching getMedicalRecords.....");
      state.getMedicalRecordsStatus = STATUS.FETCHING;
    });
    builder.addCase(getMedicalRecords.fulfilled, (state, action) => {
      Logger.log("Fetched getMedicalRecords.....");
      const { petOwners, medicalRecords } = action.payload;
      state.allRecords = filterMedicalRecords(medicalRecords);
      state.getMedicalRecordsStatus = STATUS.FETCH;
      state.petOwners = petOwners;
      state.medicalRecordsOptionsSelector = petOwners.map((item) => {
        return {
          id: item.uid,
          options: {
            email: item.email?.toLowerCase(),
            userName: item.userName ?? "",
            mobileNumber: item.mobileNumber ?? "",
          },
        };
      });
    });
    builder.addCase(getMedicalRecords.rejected, (state) => {
      Logger.log("Failed getMedicalRecords.....");
      state.getMedicalRecordsStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(addRecord.pending, (state) => {
      Logger.log("Fetching addRecord.....");
      state.addStatus = STATUS.FETCHING;
    });
    builder.addCase(addRecord.fulfilled, (state, action) => {
      Logger.log("Fetched addRecord.....");
      state.addStatus = STATUS.FETCH;
      if (action.payload) {
        state.medicalRecordSelected = action.payload;
        state.records = sortRecordItems(action.payload.records);
        state.allRecords = updateAllMedicalRecords(
          state.allRecords,
          action.payload,
        );
      }
    });
    builder.addCase(addRecord.rejected, (state, action) => {
      Logger.log("Failed addRecord.....");
      state.addStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(updateRecord.pending, (state, action) => {
      Logger.log("Fetching updateRecord.....");
      state.updateStatus = STATUS.FETCHING;
    });
    builder.addCase(updateRecord.fulfilled, (state, action) => {
      Logger.log("Fetched updateRecord.....");
      state.updateStatus = STATUS.FETCH;
      if (action.payload) {
        state.medicalRecordSelected = action.payload;
        state.records = sortRecordItems(action.payload.records);
        state.allRecords = updateAllMedicalRecords(
          state.allRecords,
          action.payload,
        );
        if (action.payload?.petOwnerUid) {
          const allMedicalRecordsFromThisPetOwner = state.allRecords.filter(
            (record) => record.petOwnerUid === action.payload!.petOwnerUid,
          );
          const pets = allMedicalRecordsFromThisPetOwner.map((item) => {
            return {
              id: item.uid,
              options: {
                petName: item.petName,
              },
            };
          });
          state.petOwnersFromMedicalRecord = pets;
        }
      }
    });
    builder.addCase(updateRecord.rejected, (state, action) => {
      Logger.log("Failed updateRecord.....");
      state.updateStatus = STATUS.FETCH_ERROR;
    });
    // builder.addCase(uploadMedicalDocument.pending, (state, action) => {
    //     Logger.log("Fetching uploadDocument.....");
    //     state.uploadDocumentStatus = STATUS.FETCHING;
    // })
    // builder.addCase(uploadMedicalDocument.fulfilled, (state, action) => {
    //     Logger.log("Fetched uploadDocument.....");
    //     state.uploadDocumentStatus = STATUS.FETCH;
    // })
    // builder.addCase(uploadMedicalDocument.rejected, (state, action) => {
    //     Logger.log("Failed uploadDocument.....");
    //     state.uploadDocumentStatus = STATUS.FETCH_ERROR;
    // })
    builder.addCase(deleteMedicalRecord.pending, (state, action) => {
      Logger.log("Fetching deleteMedicalRecord.....");
      state.deleteMedicalRecordStatus = STATUS.FETCHING;
    });
    builder.addCase(deleteMedicalRecord.fulfilled, (state, action) => {
      Logger.log("Fetched deleteMedicalRecord.....");
      state.deleteMedicalRecordStatus = STATUS.FETCH;
      if (action.payload) {
        state.allRecords = state.allRecords.filter(
          (record) => record.uid !== action.payload.uid,
        );
      }
    });
    builder.addCase(deleteMedicalRecord.rejected, (state, action) => {
      Logger.log("Failed deleteMedicalRecord.....");
      state.deleteMedicalRecordStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(createRecord.pending, (state, action) => {
      Logger.log("Fetching createRecord.....");
      state.createStatus = STATUS.FETCHING;
    });
    builder.addCase(createRecord.fulfilled, (state, action) => {
      Logger.log("Fetched createRecord.....");
      state.medicalRecordSelected = action.payload.item;
      state.allRecords = [...state.allRecords, action.payload.item!];
      state.createStatus = STATUS.FETCH;
      if (action.payload.item?.petOwnerUid) {
        state.medicalRecordSelected = action.payload.item;
        const allMedicalRecordsFromThisPetOwner = state.allRecords.filter(
          (record) => record.petOwnerUid === action.payload.item!.petOwnerUid,
        );
        const pets = allMedicalRecordsFromThisPetOwner.map((item) => {
          return {
            id: item.uid,
            options: {
              petName: item.petName,
            },
          };
        });
        state.petOwnersFromMedicalRecord = pets;
      }
      state.emailSearcherOptions = [];
    });
    builder.addCase(createRecord.rejected, (state, action) => {
      Logger.log("Failed createRecord.....");
      state.createStatus = STATUS.FETCH_ERROR;
    });
    // builder.addCase(createRecordAndAssignAppoinment.pending, (state, action) => {
    //     Logger.log("Fetching createRecordAndAssignAppoinment.....");
    //     state.createAndAssignStatus = STATUS.FETCHING;
    // })
    // builder.addCase(createRecordAndAssignAppoinment.fulfilled, (state, action) => {
    //     Logger.log("Fetched createRecordAndAssignAppoinment.....");
    //     state.medicalRecordSelected = action.payload.item;
    //     state.createAndAssignStatus = STATUS.FETCH;
    //   })
    //   builder.addCase(createRecordAndAssignAppoinment.rejected, (state, action) => {
    //     Logger.log("Failed createRecordAndAssignAppoinment.....");
    //     state.medicalRecordSelected = null;
    //     state.createAndAssignStatus = STATUS.FETCH_ERROR;
    // // })
    // builder.addCase(uploadDocumentUrl.pending, (state, action) => {
    //     Logger.log("Fetching uploadDocumentUrl.....");
    //     state.uploadDocumentStatus = STATUS.FETCHING;
    // })
    // builder.addCase(uploadDocumentUrl.fulfilled, (state, action) => {
    //     Logger.log("Fetched uploadDocumentUrl.....");
    //     state.uploadDocumentStatus = STATUS.FETCH;
    // })
    // builder.addCase(uploadDocumentUrl.rejected, (state, action) => {
    //     Logger.log("Failed uploadDocumentUrl.....");
    //     state.uploadDocumentStatus = STATUS.FETCH_ERROR;
    // })
    builder.addCase(deleteRecord.pending, (state, action) => {
      Logger.log("Fetching deleteRecord.....");
      state.deleteStatus = STATUS.FETCHING;
    });
    builder.addCase(deleteRecord.fulfilled, (state, action) => {
      Logger.log("Fetched deleteRecord.....");
      state.deleteStatus = STATUS.FETCH;
      if (action.payload) {
        state.medicalRecordSelected = action.payload;
        state.records = sortRecordItems(action.payload.records);
        state.allRecords = updateAllMedicalRecords(
          state.allRecords,
          action.payload,
        );
      }
    });
    builder.addCase(deleteRecord.rejected, (state, action) => {
      Logger.log("Failed deleteRecord.....");
      state.deleteStatus = STATUS.FETCH_ERROR;
    });
  },
});

export const {
  resetStatus,
  setSearchQuery,
  selectMedicalRecord,
  selectAppointment,
  clearMedicalRecordState,
  resetMedicalRecordState,
  searchEmailsFromMedicalRecords,
  resetMedicalRecord,
  selectMedicalRecordFromEmailAndCopyInformation,
  resetCreationStatus,
  selectMedicalRecordFromUid,
} = MedicalRecordsSlice.actions;

export default MedicalRecordsSlice.reducer;
