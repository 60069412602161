import React from "react";
import AppRoutes from "./AppRoutes";
import "./bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";

const App = (): JSX.Element => {
  return <AppRoutes />;
};

export default App;
