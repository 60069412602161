import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../service/firebase";
import { STATUS, categorySettings } from "../utils/constants";
import Logger from "../utils/Logger";
import { ISettings } from "../interfaces/category";
import { IdParam, UpdateSettingsParam } from "../interfaces/param";

export const getSettings = createAsyncThunk<ISettings, IdParam>(
  "settings/get",
  async (data: IdParam) => {
    try {
      const { id } = data;
      const docRef = doc(db, "categorias", id);
      const docSnap = await getDoc(docRef);
      const res = docSnap.data() as any;

      return (res.settings ?? categorySettings) as ISettings;
    } catch (error) {
      Logger.error(
        "Settings ::: getSettings ::: Could not update settings",
        error,
        data,
      );
      throw error;
    }
  },
);

export const updateSettings = createAsyncThunk<ISettings, UpdateSettingsParam>(
  "settings/update",
  async (data: UpdateSettingsParam) => {
    try {
      const { categoryUid, settings } = data;
      const docRef = doc(db, "categorias", categoryUid);
      await updateDoc(docRef, {
        settings: settings,
      });

      return settings;
    } catch (error) {
      Logger.error(
        "Settings ::: updateSettings ::: Could not update settings",
        error,
        data,
      );
      throw error;
    }
  },
);

const initialState: PetState = {
  settings: null,
  updateSettingStatus: STATUS.IDLE,
  getSettingStatus: STATUS.IDLE,
};

export interface PetState {
  settings: ISettings | null;
  updateSettingStatus: STATUS;
  getSettingStatus: STATUS;
}

export const SettingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    clearSettingsState: (state) => {
      state.updateSettingStatus = STATUS.IDLE;
      state.getSettingStatus = STATUS.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSettings.pending, (state, action) => {
      Logger.log("Fetching getSettings.....");
      state.getSettingStatus = STATUS.FETCHING;
    });
    builder.addCase(getSettings.fulfilled, (state, action) => {
      Logger.log("Fetched getSettings.....");
      state.settings = action.payload;
      state.getSettingStatus = STATUS.FETCH;
    });
    builder.addCase(getSettings.rejected, (state, action) => {
      Logger.log("Failed getSettings.....");
      state.getSettingStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(updateSettings.pending, (state, action) => {
      Logger.log("Fetching updateSettings.....");
      state.updateSettingStatus = STATUS.FETCHING;
    });
    builder.addCase(updateSettings.fulfilled, (state, action) => {
      Logger.log("Fetched updateSettings.....");
      state.settings = action.payload;
      state.updateSettingStatus = STATUS.FETCH;
    });
    builder.addCase(updateSettings.rejected, (state, action) => {
      Logger.log("Failed updateSettings.....");
      state.updateSettingStatus = STATUS.FETCH_ERROR;
    });
  },
});

export const { clearSettingsState } = SettingsSlice.actions;

export default SettingsSlice.reducer;
