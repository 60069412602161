import { Timestamp } from "firebase/firestore";
import moment, { Moment } from "moment-timezone";

export const getDateForFirebase = (value?: string | null) => {
  const momentDate = moment.parseZone(value);
  return momentDate?.format("YYYY-MM-DD");
};

export const getTimeForFirebase = (value?: string | null) => {
  const momentDate = moment.parseZone(value);
  return momentDate?.format("HH:mm:ss");
};

export const convertTimeFromFirebase = (
  value: Timestamp | string,
  timezone: string,
): Moment => {
  if (typeof value === "string") {
    return moment.parseZone(value);
  }
  const localTime = moment(value.toDate()).tz(timezone);
  return localTime;
  // const dateFormatted = localTime.format("YYYY-MM-DDTHH:mm:ssZ");
  // console.log("Date formatted", dateFormatted);
  // return dateFormatted;
};
