import React, { Suspense, lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Spinner from "./shared/Spinner";
import PrivateRoutes from "./components/PrivateRoute";
import Splash from "./shared/Splash";

const Layout = lazy(() => import("./components/layout/"));
const Login = lazy(() => import("./pages/login/"));
const Turnos = lazy(() => import("./pages/turnos/"));
const Profile = lazy(() => import("./pages/profile"));
const Settings = lazy(() => import("./pages/settings"));
const MedicalRecords = lazy(() => import("./pages/medicalRecords"));
const MedicalRecordsDetail = lazy(() => import("./pages/medicalRecordsDetail"));
const Waiting = lazy(() => import("./pages/waiting"));
const Unauthorized = lazy(() => import("./pages/error/Unauthorized"));
const TurnoPublic = lazy(() => import("./pages/turnoPublic"));
const CancelTurno = lazy(() => import("./pages/turnoPublic/CancelTurno"));

const AppRoutes = (): JSX.Element => {
  const enabled = localStorage.getItem("enabled") === "true";
  const token = localStorage.getItem("token");
  return (
    <Suspense fallback={<Splash />}>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route element={<PrivateRoutes />}>
              <Route element={<Profile />} path="/dashboard" />
              <Route element={<Profile />} path="/empresa" />
              <Route element={<Settings />} path="/settings" />
              <Route
                element={enabled ? <Turnos /> : <Profile />}
                path="/turnos"
              />
              <Route
                element={enabled ? <MedicalRecords /> : <Profile />}
                path="/historias-clinicas"
              />
              <Route
                element={enabled ? <MedicalRecordsDetail /> : <Profile />}
                path="/historias-clinicas/:id"
              />
              <Route
                element={enabled ? <MedicalRecords /> : <Profile />}
                path="/fichas-clientes"
              />
              <Route
                element={enabled ? <MedicalRecordsDetail /> : <Profile />}
                path="/fichas-clientes/:id"
              />
              <Route
                element={enabled ? <Waiting /> : <Profile />}
                path="/sala-de-espera"
              />
            </Route>
          </Route>

          <Route path="/:categoryId/turno" element={<TurnoPublic />} />
          <Route path="/:appointmentId/cancelar" element={<CancelTurno />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="*"
            element={<Navigate to={token ? "/dashboard" : "/login"} replace />}
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRoutes;
