import {
  convertAppointmentStatusToString,
  generateAppointments,
  getAppointmentListFromQuery,
  getEndOfDay,
  getStartOfDay,
  getStringFromApppintmentStatus,
  sanitize,
} from "./../utils/functions";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  where,
  query,
  onSnapshot,
  Timestamp,
  DocumentSnapshot,
  DocumentData,
} from "firebase/firestore";
import { db } from "../service/firebase";
import { STATUS, APPOINTMENT_STATUS } from "../utils/constants";
import Logger from "../utils/Logger";

import {
  convertAppointmentStatus,
  getMomentFromTimestamp,
} from "../utils/functions";
import { IAppointment } from "../interfaces/appointment";
import { DispatchPayload, GetAppointmentPayload } from "../interfaces/payload";
import {
  CreateAppointmentParam,
  CreateAppointmentPublicParam,
  IdDispatchParam,
  IdParam,
  UpdateAppointmentParam,
} from "../interfaces/param";
import { ICalendar } from "../interfaces/calendar";
import { AppointmentItemTile } from "../interfaces/formData";
import { Moment } from "moment-timezone";
import { AppError } from "../utils/errors";
import moment from "moment-timezone";
import {
  convertTimeFromFirebase,
  getDateForFirebase,
  getTimeForFirebase,
} from "../utils/converters";
import { apiCall } from "../utils/api";

export const getTurnos = createAsyncThunk<void, IdDispatchParam>(
  "turnos/getTurnos",
  async (data: IdDispatchParam) => {
    try {
      const { categoryUid, timezone } = data;
      const id = categoryUid;
      const collection_name = "appointments";
      const collection_ref = collection(db, collection_name);
      const q = query(
        collection_ref,
        where("categoryUid", "==", id),
        where("status", "in", [
          convertAppointmentStatusToString(APPOINTMENT_STATUS.WAITING),
          convertAppointmentStatusToString(APPOINTMENT_STATUS.CONFIRMED),
          convertAppointmentStatusToString(APPOINTMENT_STATUS.PENDING),
          convertAppointmentStatusToString(APPOINTMENT_STATUS.LOCKED),
        ]),
      );
      onSnapshot(
        q,
        (snapshot) => {
          const list = getAppointmentListFromQuery(snapshot.docs, timezone);
          data.dispatch(fillTurnosList({ appointments: list }));
        },
        (error) => {
          Logger.error("Could not stream turnos", error);
          throw new AppError("Could not stream turnos", error);
        },
      );
    } catch (error) {
      Logger.error("Could not get turnos", error);
      throw new AppError("Could not get turnos", error);
    }
  },
);

export const getWaitingRoom = createAsyncThunk<void, DispatchPayload>(
  "turnos/getWaitingRoom",
  async (data: DispatchPayload) => {
    try {
      const { dispatch } = data;
      const categoryUid = data.user.category.categoryUid;
      const timezone =
        localStorage.getItem("timezone") ?? "America/Argentina/Buenos_Aires";
      const id = categoryUid;
      const collection_name = "appointments";
      const collection_ref = collection(db, collection_name);
      // const today = new Date();
      const startOfDay = getStartOfDay(timezone).toDate();
      // const startOfDay = new Date(
      //   today.getFullYear(),
      //   today.getMonth(),
      //   today.getDate(),
      //   0,
      //   0,
      //   0
      // );
      const endOfDay = getEndOfDay(timezone).toDate();
      // const endOfDay = new Date(
      //   today.getFullYear(),
      //   today.getMonth(),
      //   today.getDate(),
      //   23,
      //   59,
      //   59
      // );
      const q = query(
        collection_ref,
        where("categoryUid", "==", id),
        where("status", "in", [
          convertAppointmentStatusToString(APPOINTMENT_STATUS.WAITING),
          convertAppointmentStatusToString(APPOINTMENT_STATUS.CONFIRMED),
          convertAppointmentStatusToString(APPOINTMENT_STATUS.CANCELED),
        ]),
        where("waitingTime", ">", startOfDay),
        where("waitingTime", "<", endOfDay),
      );

      onSnapshot(
        q,
        (snapshot) => {
          if (snapshot.docs.length === 0) return dispatch(fillWaitingList([]));

          const list = snapshot.docs.map((doc) => {
            const data = doc.data();

            const item = {
              id: doc.id as string,
              address: data.address ?? "",
              calendarTitle: data.calendarTitle ?? "",
              calendarUid: data.calendarUid ?? "",
              categoryTitle: data.categoryTitle ?? "",
              categoryUid: data.categoryUid ?? "",
              categoryUserUid: data.categoryUserUid ?? "",
              description: data.description ?? "",
              mobileNumber: data.mobileNumber ?? "",
              petUid: data.petUid ?? "",
              userName: data.userName ?? "",
              email: data.email ?? data.userEmail,
              userUid: data.userUid ?? "",
              time: convertTimeFromFirebase(data.time, timezone),
              status: convertAppointmentStatus(data.status),
              petName: data.petName ?? "",
              waitingTime: convertTimeFromFirebase(data.waitingTime, timezone),
              medicalRecordUid: data.medicalRecordUid,
              especie: data.especie || data.especies,
              raza: data.raza,
              gender: data.gender ?? "Macho",
              dob: data.dob,
            } as IAppointment;
            return item;
          });
          dispatch(fillWaitingList(list));
        },
        (error) => {
          Logger.error("Could not stream waiting room", error);
          throw new AppError("Could not stream waiting room", error);
        },
      );
    } catch (error) {
      Logger.error("Could not stream waiting room", error);
      throw new AppError("Could not stream waiting room", error);
    }
  },
);

export const getPublicTurno = createAsyncThunk<GetAppointmentPayload, IdParam>(
  "turnos/getPublicTurno",
  async (data: IdParam) => {
    try {
      const { id } = data;

      const ref = doc(db, "appointments", id);
      const result = await getDoc(ref);
      const response = (result as DocumentSnapshot).data() as
        | DocumentData
        | undefined;
      if (!response) {
        throw new AppError("El turno no existe");
      }

      if (!response.categoryUid) {
        throw new AppError("No se encontró la categoría del turno");
      }
      const cateRef = doc(db, "categorias", response.categoryUid);
      const cateSnap = await getDoc(cateRef);
      const responseCateg = cateSnap.data() as any;
      const appointment = {
        id: result.id as string,
        address: response.address ?? "",
        calendarTitle: response.calendarTitle ?? "",
        calendarUid: response.calendarUid ?? "",
        categoryTitle: response.categoryTitle ?? "",
        categoryUid: response.categoryUid ?? null,
        categoryUserUid: response.categoryUserUid ?? null,
        description: response.description ?? "",
        mobileNumber: response.mobileNumber ?? "",
        userName: response.userName ?? "",
        email: response.email ?? response.userEmail,
        time: convertTimeFromFirebase(response.time, responseCateg.timezone),
        petName: response.petName ?? "",
      } as IAppointment;

      return { appointment };
    } catch (error) {
      Logger.error("Could not get turno", error);
      throw new AppError("Could not get turno", error);
    }
  },
);

export const createAppointment = createAsyncThunk<
  IAppointment,
  CreateAppointmentParam
>("appointment/create", async (data: CreateAppointmentParam) => {
  try {
    const { formData, user } = data;

    const body = {
      address: formData.address,
      mobileNumber: formData.mobileNumber && parseInt(formData.mobileNumber),
      categoryUid: user.category.categoryUid,
      description: formData.description,
      calendarUid: formData.calendarUid,
      userName: formData.name ?? formData.userName,
      date: formData.time?.format("YYYY-MM-DD"),
      time: formData.time?.format("HH:mm:ss"),
      petUid: formData.petUid,
      email: formData.email?.toLowerCase() ?? formData.email?.toLowerCase(),
      petName: formData.petName,
      userUid: formData.userUid,
      locked: formData.status === APPOINTMENT_STATUS.LOCKED,
      medicalRecordUid: formData.medicalRecordUid,
      especie: formData.especie,
      raza: formData.raza,
      dob: formData.dob,
      gender: formData.gender,
      status: getStringFromApppintmentStatus(formData.status),
      createdFrom: "WEB_GESTION",
    };

    const response = await apiCall<any, any>({
      endpoint: "createAppointmentV2",
      body: sanitize(body),
    });

    return {
      ...response,
      status: convertAppointmentStatus(response.status),
      time: convertTimeFromFirebase(response.time, user.category.timezone),
    };
  } catch (error) {
    let errorMessage =
      "No se pudo crear el turno, por favor intente nuevamente.";
    if (error instanceof AppError) {
      errorMessage = error.message;
    }
    Logger.error("Could not assing appointment", error);
    throw new AppError(errorMessage, error);
  }
});

export const updateExistingAppointment = createAsyncThunk<
  IAppointment,
  UpdateAppointmentParam
>(
  "calendar/updateExistingAppointment",
  async (data: UpdateAppointmentParam) => {
    try {
      const { formData, id, user } = data;

      const body = {
        id,
        address: formData.address,
        mobileNumber: formData.mobileNumber && parseInt(formData.mobileNumber),
        description: formData.description,
        userName: formData.userName,
        petName: formData.petName,
        email: formData.email?.toLowerCase(),
        medicalRecordUid: formData.medicalRecordUid,
        status: getStringFromApppintmentStatus(formData.status),
        waitingTime: formData.waitingTime?.format("HH:mm:ss"),
      };

      const response = await apiCall<any, any>({
        endpoint: "updateAppointmentV2",
        body: sanitize(body),
      });

      return {
        ...response,
        time: convertTimeFromFirebase(response.time, user.category.timezone),
      };
    } catch (error) {
      Logger.error("Could not update appointment", error);
      throw new AppError("Could not update appointment", error);
    }
  },
);

export const cancelAppointment = createAsyncThunk<IAppointment, IdParam>(
  "calendar/cancelAppointment",
  async (data: IdParam) => {
    try {
      const { id } = data;

      const response = await apiCall<any, IAppointment>({
        endpoint: "cancelAppointmentV2",
        body: sanitize({
          id,
        }),
      });

      return response;
    } catch (error) {
      Logger.error("Could not cancel appointment", error);
      throw new AppError("Could not cancel appointment", error);
    }
  },
);

export const createAppointmentFromPublicLink = createAsyncThunk<
  void,
  CreateAppointmentPublicParam
>(
  "calendar/createAppointmentFromPublicLink",
  async (data: CreateAppointmentPublicParam) => {
    try {
      const { formData } = data;

      const body = {
        mobileNumber: formData.mobileNumber && parseInt(formData.mobileNumber),
        categoryUid: formData.categoryUid,
        description: formData.description,
        calendarUid: formData.calendarUid,
        userName: formData.userName,
        date: formData.time?.format("YYYY-MM-DD"),
        time: formData.time?.format("HH:mm:ss"),
        email: formData.email?.toLowerCase(),
        petName: formData.petName,
        especie: formData.especie,
        raza: formData.raza,
        address: formData.address,
        createdFrom: "PUBLIC_LINK",
      };
      await apiCall<any, any>({
        endpoint: "createAppointmentV2",
        body: sanitize(body),
      });
    } catch (error) {
      let errorMessage =
        "Hubo un error al agendar el turno, por favor intente nuevamente.";
      if (error instanceof AppError) {
        errorMessage = error.message;
      }
      Logger.error("Could not assing appointment", error);
      throw new AppError(errorMessage, error);
    }
  },
);

const initialState: TurnoState = {
  allAppointments: [],
  appointmentsForCalendarSelected: [],
  appointmentsEmpty: [],
  waitingList: [],
  publicAppointments: [],
  getStatus: STATUS.IDLE,
  deleteStatus: STATUS.IDLE,
  updateStatus: STATUS.IDLE,
  getWaitingStatus: STATUS.IDLE,
  createAppointmentStatus: STATUS.IDLE,
  cancelStatus: STATUS.IDLE,
  createAppointmentPublicStatus: STATUS.IDLE,
  error: null,
  appointmentPublic: null,
};

export interface TurnoState {
  allAppointments: IAppointment[];
  appointmentsForCalendarSelected: IAppointment[];
  publicAppointments: AppointmentItemTile[];
  waitingList: IAppointment[];
  appointmentsEmpty: AppointmentItemTile[];
  getStatus: STATUS;
  getWaitingStatus: STATUS;
  createAppointmentStatus: STATUS;
  cancelStatus: STATUS;
  updateStatus: STATUS;
  deleteStatus: STATUS;
  createAppointmentPublicStatus: STATUS;
  error: string | null;
  appointmentPublic: IAppointment | null;
}

export const TurnosSlice = createSlice({
  name: "turnos",
  initialState: initialState,
  reducers: {
    fillWaitingList: (state, action: PayloadAction<IAppointment[]>) => {
      const ordered = [...action.payload];
      const result: IAppointment[] = [];
      ordered.sort((a, b) => {
        if (!a.waitingTime || !b.waitingTime) return 0;
        return b.waitingTime.diff(a.waitingTime);
      });
      ordered.forEach((turno) => {
        const today = moment.parseZone(turno.time).clone().startOf("day");
        const tomorrow = moment.parseZone(turno.time).clone().endOf("day");
        if (moment.parseZone(turno.time)?.isBetween(today, tomorrow)) {
          result.push(turno);
        }
      });
      state.waitingList = result;
    },
    fillTurnosList: (
      state,
      action: PayloadAction<{ appointments: IAppointment[] }>,
    ) => {
      state.allAppointments = [...action.payload.appointments];
    },
    addToPublicAppointments: (
      state,
      action: PayloadAction<{ appointments: AppointmentItemTile[] }>,
    ) => {
      state.publicAppointments = [...action.payload.appointments];
    },
    clearPublicAppointments: (state) => {
      state.publicAppointments = [];
    },
    resetAppointmentStatus: (state) => {
      state.getStatus = STATUS.IDLE;
      state.createAppointmentPublicStatus = STATUS.IDLE;
      state.getWaitingStatus = STATUS.IDLE;
      state.createAppointmentStatus = STATUS.IDLE;
      state.cancelStatus = STATUS.IDLE;
    },
    resetCreateAppointmentStatus: (state) => {
      state.createAppointmentStatus = STATUS.IDLE;
    },
    fillAppointmentsFromCalendar: (
      state,
      action: PayloadAction<{ calendars: ICalendar[]; day?: Moment | null }>,
    ) => {
      const { calendars, day } = action.payload;
      const timezone =
        localStorage.getItem("timezone") ?? "America/Argentina/Buenos_Aires";
      const selectDay: Moment = day ?? moment.tz(new Date(), timezone);
      state.appointmentsEmpty = generateAppointments(calendars, selectDay);
      const uids = calendars.map((calendar) => calendar.id);
      if (uids.length === 0) {
        state.appointmentsForCalendarSelected = [];
        return;
      }
      state.appointmentsForCalendarSelected = state.allAppointments.filter(
        (appointment) => {
          return (
            uids.includes(appointment.calendarUid) &&
            appointment.time &&
            appointment.time.isSame(selectDay, "day")
          );
        },
      );
      console.log(
        "appointmentsForCalendarSelected",
        state.appointmentsForCalendarSelected,
      );
    },
    resetAppointmentState: (state) => {
      state.allAppointments = [];
      state.appointmentsForCalendarSelected = [];
      state.appointmentsEmpty = [];
      state.waitingList = [];
      state.getStatus = STATUS.IDLE;
      state.deleteStatus = STATUS.IDLE;
      state.updateStatus = STATUS.IDLE;
      state.getWaitingStatus = STATUS.IDLE;
      state.createAppointmentStatus = STATUS.IDLE;
      state.cancelStatus = STATUS.IDLE;
      state.createAppointmentPublicStatus = STATUS.IDLE;
      state.error = null;
      state.appointmentPublic = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTurnos.pending, (state) => {
      Logger.log("Fetching getTurnos.....");
      state.getStatus = STATUS.FETCHING;
    });
    builder.addCase(getTurnos.fulfilled, (state) => {
      Logger.log("Fetched getTurnos.....");
      state.getStatus = STATUS.FETCH;
    });
    builder.addCase(getTurnos.rejected, (state, action) => {
      Logger.log("Failed getTurnos.....");
      state.getStatus = STATUS.FETCH_ERROR;
      state.error = action.error.message ?? "Error desconocido";
    });
    builder.addCase(getWaitingRoom.pending, (state) => {
      Logger.log("Fetching waiting room.....");
      state.getWaitingStatus = STATUS.FETCHING;
    });
    builder.addCase(getWaitingRoom.fulfilled, (state) => {
      Logger.log("Fetched waiting room.....");
      state.getWaitingStatus = STATUS.FETCH;
    });
    builder.addCase(getWaitingRoom.rejected, (state, action) => {
      Logger.log("Failed waiting room.....");
      state.getWaitingStatus = STATUS.FETCH_ERROR;
      state.error = action.error.message ?? "Error desconocido";
    });
    builder.addCase(getPublicTurno.pending, (state) => {
      Logger.log("Fetching getPublicTurno.....");
      state.getStatus = STATUS.FETCHING;
    });
    builder.addCase(getPublicTurno.fulfilled, (state, action) => {
      Logger.log("Fetched getPublicTurno.....");
      state.getStatus = STATUS.FETCH;
      state.appointmentPublic = action.payload.appointment;
    });
    builder.addCase(getPublicTurno.rejected, (state) => {
      Logger.log("Failed getPublicTurno.....");
      state.appointmentPublic = null;
      state.getStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(cancelAppointment.pending, (state) => {
      Logger.log("Fetching cancelAppointment.....");
      state.cancelStatus = STATUS.FETCHING;
    });
    builder.addCase(cancelAppointment.fulfilled, (state) => {
      Logger.log("Fetched cancelAppointment.....");
      state.cancelStatus = STATUS.FETCH;
    });
    builder.addCase(cancelAppointment.rejected, (state) => {
      Logger.log("Failed cancelAppointment.....");
      state.cancelStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(createAppointment.pending, (state) => {
      Logger.log("Fetching createAppointment.....");
      state.createAppointmentStatus = STATUS.FETCHING;
    });
    builder.addCase(
      createAppointment.fulfilled,
      (state, action: PayloadAction<IAppointment>) => {
        Logger.log("Fetched createAppointment.....");
        state.createAppointmentStatus = STATUS.FETCH;
        state.allAppointments = [
          ...state.allAppointments.filter(
            (appointment) => appointment.id !== action.payload.id,
          ),
          action.payload,
        ];
        state.appointmentsForCalendarSelected = [
          ...state.appointmentsForCalendarSelected.filter(
            (appointment) => appointment.id !== action.payload.id,
          ),
          action.payload,
        ];
        state.getWaitingStatus = STATUS.IDLE;
      },
    );
    builder.addCase(createAppointment.rejected, (state, action) => {
      state.error = action.error.message?.toString() ?? "";
      Logger.log("Failed createAppointment.....");
      state.createAppointmentStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(updateExistingAppointment.pending, (state) => {
      Logger.log("Fetching updateExistingAppointment.....");
      state.updateStatus = STATUS.FETCHING;
    });
    builder.addCase(
      updateExistingAppointment.fulfilled,
      (state, action: PayloadAction<IAppointment>) => {
        Logger.log("Fetched updateExistingAppointment.....");
        state.updateStatus = STATUS.FETCH;
        state.getWaitingStatus = STATUS.IDLE;
        state.appointmentsForCalendarSelected = [
          ...state.appointmentsForCalendarSelected.filter(
            (appointment) => appointment.id !== action.payload.id,
          ),
          action.payload,
        ];
      },
    );
    builder.addCase(updateExistingAppointment.rejected, (state) => {
      Logger.log("Failed updateExistingAppointment.....");
      state.updateStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(createAppointmentFromPublicLink.pending, (state) => {
      Logger.log("Fetching createAppointmentFromPublicLink.....");
      state.createAppointmentPublicStatus = STATUS.FETCHING;
    });
    builder.addCase(createAppointmentFromPublicLink.fulfilled, (state) => {
      Logger.log("Fetched createAppointmentFromPublicLink.....");
      state.createAppointmentPublicStatus = STATUS.FETCH;
    });
    builder.addCase(
      createAppointmentFromPublicLink.rejected,
      (state, action) => {
        Logger.log("Failed createAppointmentFromPublicLink.....");
        state.error = action.error.message?.toString() ?? "";
        state.createAppointmentPublicStatus = STATUS.FETCH_ERROR;
      },
    );
  },
});

export const {
  fillWaitingList,
  fillTurnosList,
  resetAppointmentStatus,
  fillAppointmentsFromCalendar,
  addToPublicAppointments,
  resetCreateAppointmentStatus,
  clearPublicAppointments,
  resetAppointmentState,
} = TurnosSlice.actions;

export default TurnosSlice.reducer;
