import { DocumentSnapshot } from "firebase/firestore";
import { getAddressObject } from "../utils/functions";
import { IAddress } from "./address";

export interface ICategory {
  id: string;
  categoryUid: string;
  country: string;
  title: string;
  timezone: string;
  bio: string;
  categoryType: string;
  businessEmail: string;
  description: string;
  enable: boolean;
  mobileNumber: string[];
  userUid: string;
  picture: string;
  type: string;
  hours: string;
  favorites: string[];
  address: IAddress | null;
  tags: string[];
  dni: string;
  ratingList: string[];
  pictures: string[];
  settings: ISettings;
}

export type SettingsType = {
  type: string;
  value: any;
};
export interface ISettings {
  enableOwnerLink: SettingsType;
}

export class Category implements ICategory {
  id: string;
  categoryUid: string;
  country: string;
  title: string;
  timezone: string;
  bio: string;
  categoryType: string;
  businessEmail: string;
  description: string;
  enable: boolean;
  mobileNumber: string[];
  userUid: string;
  picture: string;
  type: string;
  hours: string;
  favorites: string[];
  address: IAddress | null;
  tags: string[];
  dni: string;
  ratingList: string[];
  pictures: string[];
  settings: ISettings;

  constructor(uid: string, data: any) {
    this.id = uid;
    this.categoryUid = uid;
    this.title = data.title ?? "";
    this.timezone = data.timezone ?? "America/Argentina/Buenos_Aires";
    this.bio = data.bio ?? "";
    this.categoryType = data.categoryType.replace("_", " ");
    this.businessEmail = data.businessEmail ?? "";
    this.description = data.description ?? "";
    this.enable = data.enable ?? false;
    this.mobileNumber = data.mobileNumber
      ? Array.isArray(data.mobileNumber)
        ? data.mobileNumber
        : [data.mobileNumber]
      : [];
    this.picture = data.picture ?? "";
    this.userUid = data.userUid;
    this.type = data.type ?? "";
    this.hours = data.hours ?? "";
    this.country = data.country ?? "Argentina";
    this.favorites = data.favorites ?? [];
    this.address = getAddressObject(data.workingAddress);
    this.tags = data.tags ? [...data.tags] : [];
    this.dni = data.dni;
    this.ratingList = data.ratingList;
    this.pictures = data.pictures;
    this.settings = data.settings;
  }

  static fromFirestore(uid: string, doc: DocumentSnapshot): Category {
    return new Category(uid, doc);
  }
}
