import {
  RazasType,
  especies,
  razas,
  vaccineDropdownList,
} from "../utils/constants";

export default class IAppData {
  constructor(
    public readonly species: string[],
    public readonly breeds: RazasType,
    public readonly vaccines: string[],
    public readonly status: { [key: string]: string | boolean },
  ) {}

  static fromJson(data: Map<string, any>, country: string): IAppData {
    const breedsMap = data.get("razas")[country] as RazasType;
    return new IAppData(
      data.get("especies")[country],
      breedsMap,
      data.get("vacunas")[country],
      {
        message: data.get("status").messageWeb,
        enable: data.get("status").enableWeb,
      },
    );
  }

  static fromConstants(): IAppData {
    return new IAppData(especies, razas, vaccineDropdownList, {
      message: "",
      enable: true,
    });
  }

  toJson(): any {
    return {
      species: this.species,
      breeds: this.breeds,
      vaccines: this.vaccines,
      status: this.status,
    };
  }
}
