import { emailIsValid } from "./validations";
import {
  DocumentData,
  GeoPoint,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import { Moment } from "moment-timezone";
import {
  APPOINTMENT_STATUS,
  EMPTY_INTERVALS,
  Intervals,
  IntervalsType,
  STATUS,
  articles,
  prepositions,
} from "./constants";
import { AppointmentItemTile } from "../interfaces/formData";
import { ICalendar } from "../interfaces/calendar";
import { IMedicalRecord } from "../interfaces/medicalRecord";
import { IRecordItem } from "../interfaces/recordItem";
import { IAddress, ILatLng } from "../interfaces/address";
import { FirebaseError } from "firebase/app";
import { IAppointment } from "../interfaces/appointment";
import moment from "moment-timezone";
import { convertTimeFromFirebase } from "./converters";

export function getDay(value: string): string {
  switch (value) {
    case "sunday":
      return "Domingo";
    case "monday":
      return "Lunes";
    case "tuesday":
      return "Martes";
    case "wednesday":
      return "Miercoles";
    case "thursday":
      return "Jueves";
    case "friday":
      return "Viernes";
    case "saturday":
      return "Sabado";
    default:
      return "Dia no valido";
  }
}

export const getIntervalDayByStringDay = (
  intervals: IntervalsType,
  day: string,
): Intervals[] => {
  switch (day) {
    case "sunday":
      return intervals.sunday;
    case "monday":
      return intervals.monday;
    case "tuesday":
      return intervals.tuesday;
    case "wednesday":
      return intervals.wednesday;
    case "thursday":
      return intervals.thursday;
    case "friday":
      return intervals.friday;
    case "saturday":
      return intervals.saturday;
    default:
      return [];
  }
};

// type KeyValue = {
//   key: string;
// };
type KeyValue = Record<string, string>;

export const generateTimeOptions = (
  intervals: IntervalsType,
  day: string,
  interval: string,
  index: number,
  duration: number,
): string[] => {
  const item = intervals[day] || [];
  let hs = 0;
  let min = 0;

  if (index === 0) {
    // For the first interval, start at 00:00
    hs = 0;
    min = 0;
  } else if (index > 0) {
    const lastEndTimeString = item[index - 1].endTime;
    const lastEndTimeArray = lastEndTimeString.split(":");
    hs = parseInt(lastEndTimeArray[0], 10);
    min = parseInt(lastEndTimeArray[1], 10);

    if (interval === "startTime") {
      min += duration; // Add the duration to the last end time for startTime
      if (min >= 60) {
        hs += Math.floor(min / 60);
        min = min % 60;
      }
    }
  }

  if (interval === "endTime") {
    // Calculate endTime based on the current startTime
    const startTimeString = item[index].startTime;
    const startTimeArray = startTimeString.split(":");
    hs = parseInt(startTimeArray[0], 10);
    min = parseInt(startTimeArray[1], 10);

    min += duration;
    if (min >= 60) {
      hs += Math.floor(min / 60);
      min = min % 60;
    }
  }

  const options: string[] = [];
  for (let hour = hs; hour < 24; hour++) {
    for (let minute = hour === hs ? min : 0; minute < 60; minute += duration) {
      const time = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
      options.push(time);
    }
  }
  return options;
};

export const generateTimes = (
  start: Moment,
  end: Moment,
  duration: number,
  calendarId: string,
): AppointmentItemTile[] => {
  const times: AppointmentItemTile[] = [];
  const current = start.clone();
  while (current <= end) {
    const newTime = current.clone();

    const turno = {
      time: newTime,
      calendarId: calendarId,
    } as AppointmentItemTile;

    times.push(turno);
    current.add(duration, "minutes");
  }
  return times;
};

export const getConvertedDate = (
  date = new Date(),
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
): Moment => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const converted = moment.tz(
    {
      year: year,
      month: month,
      date: day,
      hour: hours,
      minute: minutes,
      second: seconds,
    },
    timezone,
  ) as Moment;
  return converted;
};

/// Fecha actual en el timezone seleccionado
export const getTodayFromTimezone = (timezone: string | null) => {
  return moment.tz(
    new Date(),
    timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
};

export const getTimestampFromMoment = (
  date?: Moment | null,
): Timestamp | null => {
  if (!date) return null;
  return Timestamp.fromDate(date.toDate());
};

export const getMomentFromTimestamp = (
  date: Timestamp | null,
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone,
): Moment | null => {
  if (!date) {
    return null;
  }
  const dateString = date
    .toDate()
    .toLocaleString("en-US", { timeZone: timezone });
  const dateParts = dateString.split(/[/, :]/);
  const month = parseInt(dateParts[0], 10); // Month (numeric)
  const day = parseInt(dateParts[1], 10); // Day (numeric)
  const year = parseInt(dateParts[2], 10); // Year (numeric)

  const minutes = parseInt(dateParts[5], 10); // Minutes (numeric)
  const seconds = parseInt(dateParts[6], 10); // Seconds (numeric)
  let hours = parseInt(dateParts[4], 10); // Hour (numeric)
  const ampm = dateParts[7];

  if (ampm === "PM" && hours !== 12) {
    hours += 12; // Convert to 24-hour format
  }
  const converted = moment.tz(
    {
      year: year,
      month: month - 1,
      date: day,
      hour: hours,
      minute: minutes,
      second: seconds,
    },
    timezone,
  ) as Moment;
  converted.locale("es");
  return converted;
};

export const getOriginalMomentFromTimestamp = (
  date: Timestamp | null,
): Moment | null => {
  if (!date) {
    return null;
  }
  const timezone =
    localStorage.getItem("timezone") ?? "America/Argentina/Buenos_Aires";
  const dateInMilliseconds = date.seconds * 1000 + date.nanoseconds / 1000000;
  const dateMoment = moment.tz(dateInMilliseconds, timezone) as Moment;
  return dateMoment;
};

export const getMonthFromTimestamp = (
  date: Timestamp,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
): string => {
  const dateString = date
    .toDate()
    .toLocaleString("en-US", { timeZone: timezone });
  const dateParts = dateString.split(/[/, :]/);
  const month = parseInt(dateParts[0], 10); // Month (numeric)

  if (month === 1) {
    return "Enero";
  } else if (month === 2) {
    return "Febrero";
  } else if (month === 3) {
    return "Marzo";
  } else if (month === 4) {
    return "Abril";
  } else if (month === 5) {
    return "Mayo";
  } else if (month === 6) {
    return "Junio";
  } else if (month === 7) {
    return "Julio";
  } else if (month === 8) {
    return "Agosto";
  } else if (month === 9) {
    return "Septiembre";
  } else if (month === 10) {
    return "Octubre";
  } else if (month === 11) {
    return "Noviembre";
  } else if (month === 12) {
    return "Diciembre";
  }
  return "";
};

export const getTimestampFromString = (value: string): Timestamp => {
  return Timestamp.fromDate(new Date(value + "T12:00:00"));
};

export const getMomentFromString = (value: string): Moment => {
  return moment(value + "T12:00:00");
};

export const getConvertedMoment = (
  date: Moment,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
): Date => {
  const year = date.year();
  const month = date.month();
  const day = date.date();
  const hours = date.hour();
  const minutes = date.minute();
  const seconds = date.second();

  const converted = moment.tz(
    {
      year: year,
      month: month,
      date: day,
      hour: hours,
      minute: minutes,
      second: seconds,
    },
    timezone,
  ) as Moment;
  return converted.toDate();
};

export const createMomentFromTime = (
  date: Moment,
  time: Moment,
  timezone: string,
): Moment => {
  const mom = moment.tz(
    {
      year: date.year(),
      month: date.month(),
      date: date.date(),
      hour: time.hour(),
      minute: time.minute(),
      second: time.second(),
    },
    timezone,
  );
  return mom;
};

export const getNowMomentFromDatetime = (timezoneValue?: string): Moment => {
  const timezone =
    timezoneValue ??
    localStorage.getItem("timezone") ??
    "America/Argentina/Buenos_Aires";
  return moment().tz(timezone);
};

export const getTodayDateFromMoment = (
  date: Moment,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
): Date => {
  const year = date.year();
  const month = date.month();
  const day = date.date();

  const converted = moment.tz(
    {
      year: year,
      month: month,
      date: day,
      hour: new Date().getHours(),
      minute: new Date().getMinutes(),
      second: new Date().getSeconds(),
    },
    timezone,
  );
  return converted.toDate();
};

export const convertAppointmentStatus = (
  status: string,
): APPOINTMENT_STATUS => {
  switch (status) {
    case "WAITING":
      return APPOINTMENT_STATUS.WAITING;
    case "IDLE":
      return APPOINTMENT_STATUS.IDLE;
    case "PENDING":
      return APPOINTMENT_STATUS.PENDING;
    case "CONFIRMED":
      return APPOINTMENT_STATUS.CONFIRMED;
    case "CANCELED":
      return APPOINTMENT_STATUS.CANCELED;
    case "LOCKED":
      return APPOINTMENT_STATUS.LOCKED;
    default:
      return APPOINTMENT_STATUS.PENDING;
  }
};

export const convertAppointmentStatusToString = (
  status: APPOINTMENT_STATUS,
): string => {
  switch (status) {
    case APPOINTMENT_STATUS.WAITING:
      return "WAITING";
    case APPOINTMENT_STATUS.IDLE:
      return "IDLE";
    case APPOINTMENT_STATUS.PENDING:
      return "PENDING";
    case APPOINTMENT_STATUS.CONFIRMED:
      return "CONFIRMED";
    case APPOINTMENT_STATUS.CANCELED:
      return "CANCELED";
    case APPOINTMENT_STATUS.LOCKED:
      return "LOCKED";
    default:
      return "PENDING";
  }
};

export const createAppointmentList = (
  calendar: string[],
  duration: number,
  selectedDay: Moment,
  calendarId: string,
): AppointmentItemTile[] => {
  let temp: AppointmentItemTile[] = [];
  calendar?.forEach((turno: string) => {
    const intervals = turno.split("-");
    const start = intervals[0].split(":");
    const end = intervals[1].split(":");
    const dayInital = selectedDay.clone();
    dayInital.hour(parseInt(start[0]));
    dayInital.minute(parseInt(start[1]));
    const dayEnd = selectedDay.clone();
    dayEnd.hour(parseInt(end[0]));
    dayEnd.minute(parseInt(end[1]));
    const turnosVacios = generateTimes(dayInital, dayEnd, duration, calendarId);

    turnosVacios.pop();
    temp = [...temp, ...turnosVacios];
  });
  return temp;
};

export const retrieveAllAppointments = (
  calendar: string[],
  duration: number,
  selectedDay: Moment,
): AppointmentItemTile[] => {
  let temp: AppointmentItemTile[] = [];
  calendar?.forEach((turno: string) => {
    const intervals = turno.split("-");
    const start = intervals[0].split(":");
    const end = intervals[1].split(":");
    const dayInital = selectedDay.clone();
    dayInital.hour(parseInt(start[0]));
    dayInital.minute(parseInt(start[1]));
    const dayEnd = selectedDay.clone();
    dayEnd.hour(parseInt(end[0]));
    dayEnd.minute(parseInt(end[1]));
    const turnosVacios = generateTimes(dayInital, dayEnd, duration, "");
    turnosVacios.pop();
    temp = [...temp, ...turnosVacios];
  });
  return temp;
};

export const getValueFromMultipleArgs = (...args: any) => {
  const val = args.find(
    (arg: any) => arg !== null && arg !== undefined && arg !== "",
  );
  return val ?? null;
};

export const filterMedicalRecords = (records: any) => {
  const sortedRecords = [...records];
  sortedRecords.sort((a, b) => {
    const dateA = a.records[0]?.date || null;
    const dateB = b.records[0]?.date || null;
    // Manejar casos donde una o ambas fechas son nulas
    if (dateA === null && dateB === null) {
      return 0;
    } else if (dateA === null) {
      return 1;
    } else if (dateB === null) {
      return -1;
    }
    return dateB - dateA;
  });
  return sortedRecords;
};

export const updateAllMedicalRecords = (
  allRecords: IMedicalRecord[],
  recordEdited: IMedicalRecord,
) => {
  const index = allRecords.findIndex(
    (item: any) => item.uid === recordEdited.uid,
  );
  if (index !== -1) {
    allRecords[index] = recordEdited;
  }
  const sorted = [...allRecords];
  sorted.forEach((record: IMedicalRecord) => {
    const sortedRecords = Array.isArray(record.records)
      ? [...record.records]
      : [];
    return sortedRecords.sort((a, b) => {
      if (!a.date || !b.date) return 0;
      return b.date.toDate().getTime() - a.date.toDate().getTime();
    });
  });
  return sorted;
};

export const isFetchingStatus = (status: STATUS): boolean => {
  return status === STATUS.FETCHING;
};

export const addIntervalIntoIntervalDays = (
  value: IntervalsType,
  day: string,
): IntervalsType => {
  const getDay = [...value[day as keyof IntervalsType]];
  getDay.push({ startTime: "", endTime: "" });
  const result = {
    ...value,
    [day]: getDay,
  };
  return result;
};

export const removeIntervalFromIntervalDays = (
  value: IntervalsType,
  day: string,
  index: number,
): IntervalsType => {
  const getDay = [...value[day as keyof IntervalsType]];
  getDay.splice(index, 1);
  const result = {
    ...value,
    [day]: getDay,
  };
  return result;
};

export const updateIntervalFromIntervalDays = (
  value: IntervalsType,
  day: string,
): IntervalsType => {
  let getDay = [...value[day as keyof IntervalsType]];
  if (getDay.length === 0) {
    getDay.push({ startTime: "", endTime: "" });
  } else {
    getDay = [];
  }
  const result = {
    ...value,
    [day]: getDay,
  };
  return result;
};

// export const updateIntervalItemFromIntervalDays = (value: IntervalsType, day: string, index: number, field: string, newValue: string): IntervalsType => {
//   let getDay = value[day as keyof IntervalsType]
//   getDay[index][field as keyof Intervals] = newValue;
//   value[day] = getDay;
//   return value;
// }

export const updateIntervalItemFromIntervalDays = (
  value: IntervalsType,
  day: string,
  index: number,
  field: string,
  newValue: string,
): IntervalsType => {
  const getDay = [...value[day as keyof IntervalsType]];

  getDay[index][field as keyof Intervals] = newValue;
  // if (field === "startTime") {
  //   getDay[index].endTime = "";
  // }
  const result = {
    ...value,
    [day]: getDay,
  };
  return result;
};

export const intervalsByDayHasEmptyValues = (value: IntervalsType): boolean => {
  let hasEmptyValues = false;
  Object.keys(value).forEach((key) => {
    const getDay = value[key];
    getDay.forEach((interval) => {
      if (interval.startTime === "" || interval.endTime === "") {
        hasEmptyValues = true;
      }
    });
  });
  return hasEmptyValues;
};

export const clearIntervalsByDay = (value: IntervalsType): IntervalsType => {
  Object.keys(value).forEach((key) => {
    const getDay = value[key];
    getDay.splice(0, getDay.length);
  });
  return value;
};

export const isIntervalDisabled = (
  value: IntervalsType,
  day: string,
  index: number,
): boolean => {
  const getDay = value[day];
  return getDay.length > index + 1;
};

export const getCalendarFromDayNumber = (
  value: ICalendar,
  day: number,
): string[] => {
  switch (day) {
    case 0:
      return value.sunday;
    case 1:
      return value.monday;
    case 2:
      return value.tuesday;
    case 3:
      return value.wednesday;
    case 4:
      return value.thursday;
    case 5:
      return value.friday;
    case 6:
      return value.saturday;
    default:
      return [];
  }
};

export const generateAppointments = (
  values: ICalendar[],
  day: Moment,
): AppointmentItemTile[] => {
  const results: AppointmentItemTile[] = [];
  for (const item of values) {
    const calendarTimesString = getCalendarFromDayNumber(item, day.day());
    const duration = item.duration;
    const calendarId = item.id;

    const selectedCalendarById = values.find(
      (value) => value.id === calendarId,
    );

    const workingDays = selectedCalendarById?.daysOff?.find((i) => {
      const old = i.format("MMM D, YYYY");
      const momen = day.format("MMM D, YYYY");
      return old === momen;
    });
    if (workingDays || !calendarTimesString || !duration) {
      continue;
    }

    const res = createAppointmentList(
      calendarTimesString,
      duration,
      day,
      calendarId,
    );
    results.push(...res);
  }
  results.sort((a, b) => {
    return a.time.toDate().getTime() - b.time.toDate().getTime();
  });
  return results;
};

type Sanitizable = { [key: string]: any } | any[];

export const sanitize = (obj: any): any => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(sanitize);
  }

  if (obj instanceof Timestamp) {
    return obj;
  }
  if (obj instanceof GeoPoint) {
    return obj;
  }
  if (moment.isMoment(obj)) {
    return getTimestampFromMoment(obj);
  }

  const sanitizedObject = Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => value !== undefined)
      .map(([key, value]) => [key, sanitize(value)]),
  );

  return sanitizedObject;
};

export const getMonthFromMoment = (date?: Moment | null): string => {
  const month = date?.month();
  if (month === 1) {
    return "Enero";
  } else if (month === 2) {
    return "Febrero";
  } else if (month === 3) {
    return "Marzo";
  } else if (month === 4) {
    return "Abril";
  } else if (month === 5) {
    return "Mayo";
  } else if (month === 6) {
    return "Junio";
  } else if (month === 7) {
    return "Julio";
  } else if (month === 8) {
    return "Agosto";
  } else if (month === 9) {
    return "Septiembre";
  } else if (month === 10) {
    return "Octubre";
  } else if (month === 11) {
    return "Noviembre";
  } else if (month === 12) {
    return "Diciembre";
  }
  return "";
};

export const getArray = (obj: any): any[] => {
  if (Array.isArray(obj)) {
    return obj;
  }
  return [];
};

export const sortRecordItems = (
  items?: IRecordItem[] | null,
): IRecordItem[] => {
  if (!items) return [];
  const sortedRecords = [...items];
  sortedRecords.sort((a, b) => {
    if (!a.date || !b.date) return 0;
    return b.date!.toDate().getTime() - a.date!.toDate().getTime();
  });
  return sortedRecords;
};

export const atLeastOneIsFetching = (statuses: STATUS[]): boolean => {
  return statuses.some((status) => status === STATUS.FETCHING);
};

export const removeDuplicates = (input: string): string => {
  const items = input.split(", ");
  const uniqueItems = Array.from(new Set(items));
  return uniqueItems.join(", ");
};

export const getAddressString = (address?: IAddress | null): string => {
  if (!address) return "";
  let addressString = address.fullAddress ?? "";
  if (address.route) {
    addressString += `${address.route}, `;
  }
  if (address.streetNumber) {
    addressString += `${address.streetNumber}, `;
  }
  if (address.sublocality) {
    addressString += `${address.sublocality}, `;
  }
  if (address.locality) {
    addressString += `${address.locality}, `;
  }
  if (address.province) {
    addressString += `${address.province}, `;
  }
  if (address.country) {
    addressString += `${address.country}, `;
  }
  if (addressString.endsWith(", ")) {
    addressString = addressString.slice(0, -2);
  }
  return removeDuplicates(addressString);
};

export const getStringFromApppintmentStatus = (
  status?: APPOINTMENT_STATUS | null,
): string => {
  switch (status) {
    case APPOINTMENT_STATUS.WAITING:
      return "WAITING";
    case APPOINTMENT_STATUS.IDLE:
      return "IDLE";
    case APPOINTMENT_STATUS.PENDING:
      return "PENDING";
    case APPOINTMENT_STATUS.CONFIRMED:
      return "CONFIRMED";
    case APPOINTMENT_STATUS.CANCELED:
      return "CANCELED";
    case APPOINTMENT_STATUS.LOCKED:
      return "LOCKED";
    default:
      return "PENDING";
  }
};
export const getStatusFromAppointmentStatusString = (
  status?: string,
): APPOINTMENT_STATUS => {
  switch (status) {
    case "WAITING":
      return APPOINTMENT_STATUS.WAITING;
    case "IDLE":
      return APPOINTMENT_STATUS.IDLE;
    case "PENDING":
      return APPOINTMENT_STATUS.PENDING;
    case "CONFIRMED":
      return APPOINTMENT_STATUS.CONFIRMED;
    case "CANCELED":
      return APPOINTMENT_STATUS.CANCELED;
    case "LOCKED":
      return APPOINTMENT_STATUS.LOCKED;
    default:
      return APPOINTMENT_STATUS.PENDING;
  }
};

export const getStatusName = (status?: STATUS | null): STATUS => {
  switch (status) {
    case 0:
      return STATUS.IDLE;
    case 1:
      return STATUS.FETCHING;
    case 2:
      return STATUS.FETCH;
    case 3:
      return STATUS.FETCH_ERROR;
    default:
      return STATUS.IDLE;
  }
};

export const isCalendarTileDisabled = (
  date: Date,
  calendar?: ICalendar | null,
): boolean => {
  const momentDate = moment(date);

  if (
    calendar?.daysOff?.find((dayOff) => {
      const old = dayOff.format("MMM D, YYYY");
      const momen = momentDate.format("MMM D, YYYY");
      return old === momen;
    })
  ) {
    return true;
  }
  const ttm = moment().startOf("day");
  if (momentDate.isBefore(ttm)) {
    return true;
  }

  if (date > moment().add(1, "month").toDate()) {
    return true;
  }

  const tempDay = date.getDay();
  switch (tempDay) {
    case 1:
      return calendar?.monday.length === 0;
    case 2:
      return calendar?.tuesday.length === 0;
    case 3:
      return calendar?.wednesday.length === 0;
    case 4:
      return calendar?.thursday.length === 0;
    case 5:
      return calendar?.friday.length === 0;
    case 6:
      return calendar?.saturday.length === 0;
    case 0:
      return calendar?.sunday.length === 0;
    default:
      return true;
  }
};

export const getWeekdayCalendar = (
  calendar: ICalendar,
  todayWeekDay: number,
): string[] => {
  switch (todayWeekDay) {
    case 7:
    case 0:
      return calendar.sunday;
    case 1:
      return calendar.monday;
    case 2:
      return calendar.tuesday;
    case 3:
      return calendar.wednesday;
    case 4:
      return calendar.thursday;
    case 5:
      return calendar.friday;
    case 6:
      return calendar.saturday;
    default:
      return [];
  }
};

export const getFirstNextActiveDay = (calendar?: ICalendar | null): Date => {
  const today: Moment = moment();
  const todayWeekDay = today.day();
  if (!calendar) return new Date();
  const weekdays: number[] = [];
  let i = todayWeekDay + 1;

  weekdays.push(todayWeekDay);
  while (i !== todayWeekDay && weekdays.length < 7) {
    weekdays.push(i);
    if (i === 7) {
      i = 0;
    }
    i++;
  }
  let nextWeekDay = todayWeekDay;
  for (const e of weekdays) {
    const weekdayCalendar = getWeekdayCalendar(calendar, e);
    if (
      weekdayCalendar.length === 0 ||
      isNowGreaterThanLastTime(weekdayCalendar, e)
    )
      continue;
    nextWeekDay = e;
    break;
  }

  const nextDay = today.clone().day(nextWeekDay);
  if (nextDay.isBefore(today)) {
    return nextDay.add(1, "week").toDate();
  } else {
    return nextDay.toDate();
  }
};

export const timeStringToDate = (time: string, weekday: number): Date => {
  const [hours, minutes] = time.split(":").map(Number);
  const targetWeekdayAdjusted = weekday % 7;
  const now = new Date();
  const currentDayOfWeek = now.getDay();
  let daysToAdd = targetWeekdayAdjusted - currentDayOfWeek;
  if (daysToAdd < 0) {
    // If target day has already passed this week, calculate days until next occurrence
    daysToAdd += 7;
  }
  const targetDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + daysToAdd,
    hours,
    minutes,
    0,
    0,
  );
  return targetDate;
};

export const isNowGreaterThanLastTime = (
  times: string[],
  weekday: number,
): boolean => {
  // Get the last time period and split it to start and end times
  const lastTimePeriod = times[times.length - 1];
  const [, endTime] = lastTimePeriod.split("-");

  // Convert both the current time and the end time to Date objects
  const now = new Date();
  const endTimeDate = timeStringToDate(endTime, weekday);

  // Compare the current time with the end time
  return now > endTimeDate;
};

export const getAddressObject = (data?: any): IAddress | null => {
  if (!data) return null;
  return {
    country: data.country ?? "",
    locality: data.locality ?? "",
    sublocality: data.sublocality ?? "",
    political: data.political ?? "",
    postCode: data.postCode ?? "",
    province: data.province ?? "",
    radius: data.radius,
    route: data.route ?? "",
    streetNumber: data.streetNumber ?? "",
    fullAddress: data.fullAddress,
    latLng: data.latLng
      ? ({
          lat: data.latLng.latitude,
          lng: data.latLng.longitude,
        } as ILatLng)
      : null,
  } as IAddress;
};
export const handleKeyDown = (event: React.KeyboardEvent<any>) => {
  if (event.key === "Enter") {
    event.preventDefault();
  }
};

export const isVete = (): boolean => {
  const isVete =
    localStorage.getItem("categoryType") === "medico_veterinario" ||
    localStorage.getItem("categoryType") === "clinica_veterinaria";
  return isVete;
};

export const getErrorMessage = (
  error?: FirebaseError | null | unknown,
): string => {
  if (!error) return "Ha ocurrido un error, por favor intente nuevamente";
  if (error instanceof FirebaseError) {
    if (error.code === "auth/user-not-found") return "El usuario no existe";
    if (error.code === "auth/wrong-password")
      return "Usuario o contraseña incorrectas";
    if (error.code === "auth/email-already-in-use")
      return "El email ya esta en uso";
    if (error.code === "auth/invalid-email") return "El email es invalido";
    if (error.code === "auth/account-exists-with-different-credential")
      return "El email ya esta en uso, pruebe iniciar de otro modo.";
    if (error.code === "auth/popup-closed-by-user")
      return "El popup fue cerrado por el usuario";
  }
  return error.toString();
};

export const getStartOfDay = (timezone?: string | null): Moment => {
  const timezoneFixed = timezone ?? "America/Argentina/Buenos_Aires";
  return moment.tz(new Date(), timezoneFixed).startOf("day");
};

export const getEndOfDay = (timezone?: string | null): Moment => {
  const timezoneFixed = timezone ?? "America/Argentina/Buenos_Aires";
  return moment.tz(new Date(), timezoneFixed).endOf("day");
};

export const getAppointmentListFromQuery = (
  docs: QueryDocumentSnapshot<DocumentData, DocumentData>[],
  timezone: string,
): IAppointment[] => {
  if (docs.length === 0) return [];

  return docs.map((doc) => {
    const data = doc.data();
    const item = {
      id: doc.id as string,
      address: data.address ?? "",
      calendarTitle: data.calendarTitle ?? "",
      calendarUid: data.calendarUid ?? "",
      categoryTitle: data.categoryTitle ?? "",
      categoryUid: data.categoryUid ?? "",
      categoryUserUid: data.categoryUserUid ?? "",
      description: data.description ?? "",
      mobileNumber: data.mobileNumber ?? "",
      petUid: data.petUid ?? "",
      userName: data.userName ?? "",
      email: data.email ?? data.userEmail,
      userUid: data.userUid ?? "",
      time: convertTimeFromFirebase(data.time, timezone),
      petName: data.petName,
      status: convertAppointmentStatus(data.status),
      medicalRecordUid: data.medicalRecordUid ?? "",
      especie: data.especie || data.especies,
      raza: data.raza,
      gender: data.gender ?? "Macho",
      dob: data.dob,
    } as IAppointment;
    return item;
  });
};

export const fixEspecie = (especie?: string | null): string | null => {
  if (!especie) return null;
  if (especie.toLowerCase() === "canino") return "Perro";
  if (especie.toLowerCase() === "felino") return "Gato";
  return especie;
};

export const listMedicalRecordSorted = (
  list: IMedicalRecord[],
): IMedicalRecord[] => {
  const arr = Object.values(list);
  return arr.sort((a, b) => {
    if (a.petName.toLowerCase() < b.petName.toLowerCase()) return -1;
    if (a.petName.toLowerCase() > b.petName.toLowerCase()) return 1;
    return 0;
  });
};

export const formatDate = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, "0"); // Get the day and pad with zero if necessary
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get the month, add 1 (as January is 0) and pad
  const year = date.getFullYear(); // Get the full year
  return `${day}/${month}/${year}`; // Format the date in DD/MM/YYYY
};

export const filterMedicalRecordsOnSearcg = (
  records: IRecordItem[],
  searchQuery: string,
) => {
  return records.filter((item) => {
    const lowerCasedItem = {
      description: item.description?.toLowerCase(),
      date: item.date?.format("DD/MM/YYYY"),
      month: getMonthFromMoment(item.date).toLowerCase(),
    };

    return searchQuery
      .toLowerCase()
      .split(" ")
      .every((word) => {
        if (prepositions.includes(word) || articles.includes(word)) {
          return true;
        }
        return Object.values(lowerCasedItem).some(
          (field) => field?.includes(word),
        );
      });
  });
};

export const fillAllIntervalsCalendarOnEdit = (
  calendar: ICalendar,
): IntervalsType => {
  const intervalsResponse = { ...EMPTY_INTERVALS };
  intervalsResponse.sunday =
    calendar.sunday?.map((item) => ({
      startTime: item.split("-")[0],
      endTime: item.split("-")[1],
    })) ?? [];
  intervalsResponse.monday =
    calendar.monday?.map((item) => ({
      startTime: item.split("-")[0],
      endTime: item.split("-")[1],
    })) ?? [];
  intervalsResponse.tuesday =
    calendar.tuesday?.map((item) => ({
      startTime: item.split("-")[0],
      endTime: item.split("-")[1],
    })) ?? [];
  intervalsResponse.wednesday =
    calendar.wednesday?.map((item) => ({
      startTime: item.split("-")[0],
      endTime: item.split("-")[1],
    })) ?? [];
  intervalsResponse.thursday =
    calendar.thursday?.map((item) => ({
      startTime: item.split("-")[0],
      endTime: item.split("-")[1],
    })) ?? [];
  intervalsResponse.friday =
    calendar.friday?.map((item) => ({
      startTime: item.split("-")[0],
      endTime: item.split("-")[1],
    })) ?? [];
  intervalsResponse.saturday =
    calendar.saturday?.map((item) => ({
      startTime: item.split("-")[0],
      endTime: item.split("-")[1],
    })) ?? [];
  return intervalsResponse;
};

const TIME_INTERVAL = 15; // Time interval in minutes

// Generates a list of time strings in 15-minute intervals
export function generateTimeList(
  isInitial: boolean,
  startFrom: string | null,
  duration: number | null,
): string[] {
  const times: string[] = [];
  const [hourInitial, minuteInitial] = (startFrom ?? "00:00")!
    .split(":")
    .map(Number);

  let hour = startFrom ? hourInitial : 0;
  let minute = startFrom ? minuteInitial : 0;
  for (hour; hour < 24; hour++) {
    for (
      minute;
      minute < 60;
      minute += isInitial ? TIME_INTERVAL : duration ?? TIME_INTERVAL
    ) {
      const time = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
      times.push(time);
    }
    // minute = 45 + 45 - 60 = 30
    // minute = 30 + 45 - 60 = 15
    // minute = 15 + 45 - 60 = 0
    // minute = 0 + 45 - 60 = 45
    minute = !isInitial ? minute - 60 : 0;
  }
  return times;
}

// Converts a time string (e.g., "14:30") to minutes past midnight
export function timeToMinutes(time: string): number {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
}

// Converts minutes past midnight to a time string (e.g., "14:30")
export function minutesToTime(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, "0")}:${mins
    .toString()
    .padStart(2, "0")}`;
}

// Generates the available time list based on the selected start time and duration
export function getAvailableTimes(
  isInitial: boolean,
  startTime: string | null,
  duration: number,
  previousIntervals: Intervals[],
): string[] {
  const durationForGenerator = startTime ? duration : null;
  const times = generateTimeList(isInitial, startTime, durationForGenerator);
  const maxTime = 24 * 60; // Latest possible start time in minutes

  // Filter the available times based on the previous intervals
  const startTimeMinutes = startTime ? timeToMinutes(startTime) : 0;
  // const endTimeMinutes = startTimeMinutes + duration;
  if (!startTime) {
    return times;
  }
  return times.filter((time) => {
    const timeMinutes = timeToMinutes(time);

    // Check if the time is within the allowed range
    if (timeMinutes < startTimeMinutes) return false;
    if (timeMinutes > maxTime) return false;

    // Check if the time overlaps with any previous intervals
    for (const interval of previousIntervals) {
      const intervalStart = timeToMinutes(interval.startTime);
      const intervalEnd = timeToMinutes(interval.endTime);
      if (timeMinutes >= intervalStart && timeMinutes < intervalEnd) {
        return false;
      }
    }

    return true;
  });
}

export const calendarTimesContainsWrongValues = (
  intervals: IntervalsType,
  duration: number,
): boolean => {
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  return days.some((day) => {
    return (intervals[day] as Intervals[]).some((interval) => {
      if (interval.startTime === "" || interval.endTime === "") {
        return false;
      }
      const startTimeMinutes = timeToMinutes(interval.startTime);
      const endTimeMinutes = timeToMinutes(interval.endTime);

      // Check if start and end times are multiples of 15
      if (startTimeMinutes % 15 !== 0 || endTimeMinutes % 15 !== 0) {
        return true;
      }

      // Check if the total time range can accommodate an integer number of appointments
      const totalDuration = endTimeMinutes - startTimeMinutes;
      if (totalDuration % duration !== 0) {
        return true;
      }

      return false;
    });
  });
};

export function getInitialTimeRangeForCalendar(
  currentTime: string,
  startTime: string | null,
  endTime: string | null,
  duration: number,
  includeTimesBefore: boolean,
  keepRangeOnEdit: boolean,
): string[] {
  const [currentHour, currentMinute] = (currentTime ?? "00:00")
    .split(":")
    .map(Number);
  const currentTotalMinutes = currentHour * 60 + currentMinute;
  const endTotalMinutes = endTime
    ? parseInt(endTime.split(":")[0]) * 60 + parseInt(endTime.split(":")[1])
    : 24 * 60;
  const times: string[] = [];
  let slotDuration = 15;
  if (keepRangeOnEdit) {
    slotDuration = duration;
  }

  if (startTime) {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const startTotalMinutes = startHour * 60 + startMinute;

    // Generate times before the currentTime up to startTime
    for (
      let currentMinutes = currentTotalMinutes - slotDuration;
      currentMinutes >= startTotalMinutes;
      currentMinutes -= slotDuration
    ) {
      const hours = Math.floor(currentMinutes / 60);
      const minutes = currentMinutes % 60;
      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      times.unshift(formattedTime); // Add to the beginning of the array
    }
  }

  // Generate times after the currentTime
  for (
    let currentMinutes = currentTotalMinutes;
    currentMinutes < endTotalMinutes;
    currentMinutes += slotDuration
  ) {
    const hours = Math.floor(currentMinutes / 60);
    const minutes = currentMinutes % 60;
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    times.push(formattedTime);
  }

  if (includeTimesBefore) {
    // Generate times before the currentTime
    for (
      let currentMinutes = currentTotalMinutes - slotDuration;
      currentMinutes >= 0;
      currentMinutes -= slotDuration
    ) {
      const hours = Math.floor(currentMinutes / 60);
      const minutes = currentMinutes % 60;
      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      times.unshift(formattedTime); // Add to the beginning of the array
    }
  }

  return times;
}

export function getEndTimeRangeForCalendar(
  startTime: string,
  endTime: string | null,
  duration: number,
): string[] {
  const [startHour, startMinute] = startTime.split(":").map(Number);
  const startTotalMinutes = startHour * 60 + startMinute;
  const endTotalMinutes = endTime
    ? parseInt(endTime.split(":")[0]) * 60 + parseInt(endTime.split(":")[1])
    : 24 * 60;
  const times: string[] = [];

  // Adjust start time to include at least one duration slot
  const adjustedStartTotalMinutes = startTotalMinutes + duration;

  // Generate times after the adjusted startTime
  for (
    let currentMinutes = adjustedStartTotalMinutes;
    currentMinutes < endTotalMinutes;
    currentMinutes += duration
  ) {
    const hours = Math.floor(currentMinutes / 60);
    const minutes = currentMinutes % 60;
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    times.push(formattedTime);
  }

  return times;
}
export const getCalendarListFromQuery = (
  docs: QueryDocumentSnapshot<DocumentData, DocumentData>[],
  timezone: string,
): ICalendar[] => {
  if (docs.length === 0) return [];

  return docs.map((doc) => {
    const data = doc.data();
    const item = {
      id: doc.id as string,
      daysOff: getArray(data.daysOff).map((day: Timestamp) => {
        return getMomentFromTimestamp(day, timezone);
      }),
      duration: data.duration as number,
      title: data.title as string,
      description: data.description as string | null,
      monday: getArray(data.monday),
      tuesday: getArray(data.tuesday),
      wednesday: getArray(data.wednesday),
      thursday: getArray(data.thursday),
      friday: getArray(data.friday),
      saturday: getArray(data.saturday),
      sunday: getArray(data.sunday),
    } as ICalendar;
    return item;
  });
};
export const fetchFilesAsBlobs = async (urls: string[]) => {
  const fetches = urls.map(async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  });
  return Promise.all(fetches);
};
