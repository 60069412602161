import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDoc, doc, DocumentData } from "firebase/firestore";
import { db } from "../service/firebase";
import { STATUS } from "../utils/constants";
import Logger from "../utils/Logger";
import { AppError } from "../utils/errors";
import { CategoryTypePayload } from "../interfaces/payload";
import { ITag } from "../interfaces/tag";

export const getCategoryTag = createAsyncThunk<ITag, CategoryTypePayload>(
  "tagCategory/get",
  async (data: CategoryTypePayload) => {
    try {
      const { categoryType } = data;
      const docRef = doc(db, "app_data", "tags");
      const docSnap = await getDoc(docRef);
      const res = (docSnap.data() as DocumentData)[
        categoryType.replaceAll(" ", "_")
      ];
      const response = {
        tags: res[0]?.tags ? [...(res[0]?.tags ?? [])] : null,
        types: res[1]?.tags ? [...(res[1]?.tags ?? [])] : null,
      } as ITag;
      return response;
    } catch (error) {
      Logger.error("Could not get tags category", error);
      throw new AppError("Could not get tags category", error);
    }
  },
);

const initialState: TagState = {
  tagCategory: null,
  getStatus: STATUS.IDLE,
};

export interface TagState {
  tagCategory: ITag | null;
  getStatus: STATUS;
}

export const TagsSlice = createSlice({
  name: "tags",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategoryTag.pending, (state) => {
      Logger.log("Fetching getCategoryTag.....");
      state.getStatus = STATUS.FETCHING;
    });
    builder.addCase(
      getCategoryTag.fulfilled,
      (state, action: PayloadAction<ITag>) => {
        Logger.log("Fetched getCategoryTag.....");
        state.tagCategory = action.payload;
        state.getStatus = STATUS.FETCH;
      },
    );
    builder.addCase(getCategoryTag.rejected, (state) => {
      Logger.log("Failed getCategoryTag.....");
      state.getStatus = STATUS.FETCH_ERROR;
    });
  },
});

export default TagsSlice.reducer;
