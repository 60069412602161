import React from "react";
import { FaTools } from "react-icons/fa"; // Using react-icons for an icon

export interface IMaintenance {
  message: string;
}

const Maintenance = (props: IMaintenance): JSX.Element => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center maintenance-container"
      style={{ height: "100vh", backgroundColor: "#f8d7da", color: "#721c24" }}
    >
      <FaTools size={50} style={{ marginBottom: "20px" }} />
      <h1 style={{ marginBottom: "10px" }}>Volveremos pronto!</h1>
      <p style={{ fontSize: "18px", textAlign: "center" }}>{props.message}</p>
    </div>
  );
};

export default Maintenance;
