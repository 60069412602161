import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db, storage } from "../service/firebase";
import { STATUS, categorySettings } from "../utils/constants";
import Logger from "../utils/Logger";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { ICategoryPayload } from "../interfaces/payload";
import { ICategory, ISettings } from "../interfaces/category";
import {
  IdAndCategoryParam,
  IdParam,
  UploadDocsParam,
  UploadPicParam,
} from "../interfaces/param";
import { getAddressObject, sanitize } from "../utils/functions";
import { AppError } from "../utils/errors";

// export const deleteCategoryDocument = createAsyncThunk(
//   "categories/deleteDocument",
//   async (data: any) => {
//     try {
//       const id = data.id;
//       const docNumber = data.doc.split("%2F").slice(-1)[0].split("?")[0];
//       const categoryType = data.categoryType.replace(" ", "_");
//       const path = `/categories/${categoryType}/${id}/docs/${docNumber}`;
//       const storage = getStorage();

//       const storageRef = ref(storage, path);

//       await deleteObject(storageRef);

//       const docRef = doc(db, "categorias", data.id);
//       await updateDoc(docRef, {
//         documentos: arrayRemove(data.doc),
//       });
//       window.location.reload();
//     } catch (error) {
//       Logger.error("Categories ::: updateRecord ::: Could not delete category picture", error);
//     }
//   }
// );

export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  async (data: IdAndCategoryParam) => {
    try {
      const { id, category } = data;
      const body = {
        title: category.title,
        title_lowercase: category.title?.toLowerCase() ?? null,
        bio: category.bio,
        businessEmail: category.businessEmail?.toLowerCase() ?? null,
        description: category.description,
        mobileNumber: category.mobileNumber,
        hours: category.hours,
        workingAddress: category.address,
        // documentos: [...category.docs],
        tags: [...category.tags],
        dni: category.dni,
        // pictures: category.pictures,
      };
      const docRef = doc(db, "categorias", id);
      await updateDoc(docRef, sanitize(body));
    } catch (error) {
      Logger.error(
        "Categories ::: updateCategory ::: Could not update category",
        error,
        data,
      );
      throw new AppError("Could not update category", error);
    }
  },
);

// export const getCategorySelected = createAsyncThunk<ICategoryPayload, IdParam>(
//   "categories/getSelected",
//   async (data: IdParam) => {
//     try {
//       const { id } = data;
//       const userRef = doc(db, "users", id);
//       const userSnap = await getDoc(userRef);
//       const categoryUid = userSnap.data()?.categoryUid;

//       const docRef = doc(db, "categorias", categoryUid);
//       const docSnap = await getDoc(docRef);
//       const res = docSnap.data() as any;

//       const category = {
//         id: docSnap.id as string,
//         categoryUid: categoryUid,
//         title: res.title ?? "",
//         timezone: res.timezone ?? "America/Argentina/Buenos_Aires",
//         bio: res.bio ?? "",
//         categoryType: res.categoryType.replace("_", " "),
//         businessEmail: res.businessEmail ?? "",
//         description: res.description ?? "",
//         enable: res.enable ?? false,
//         mobileNumber: res.mobileNumber
//           ? Array.isArray(res.mobileNumber)
//             ? res.mobileNumber
//             : [res.mobileNumber]
//           : [],
//         picture: res.picture ?? "",
//         userUid: res.userUid as string,
//         type: res.type ?? "",
//         hours: res.hours ?? "",
//         favorites: res.favorites ?? [],
//         address: getAddressObject(res.workingAddress),
//         docs: [...res.documentos],
//         tags: res.tags ? [...res.tags] : [],
//         dni: res.dni,
//         ratingList: res.ratingList,
//         pictures: res.pictures,
//       } as ICategory;
//       return { category };
//     } catch (error) {
//       Logger.error(
//         "Categories ::: getCategorySelected ::: Could not get category",
//         error,
//         data,
//       );
//       throw new AppError("Could not get category", error);
//     }
//   },
// );

export const getPublicCategory = createAsyncThunk<ICategoryPayload, IdParam>(
  "categories/getCategoryPublic",
  async (data: IdParam) => {
    try {
      const { id } = data;
      const docRef = doc(db, "categorias", id);
      const docSnap = await getDoc(docRef);
      const res = docSnap.data() as any;

      const category = {
        id: docSnap.id,
        categoryUid: res.categoryUid,
        title: res.title,
        timezone: res.timezone,
        bio: res.bio,
        categoryType: res.categoryType.replace("_", " "),
        businessEmail: res.businessEmail,
        description: res.description,
        enable: res.enable,
        mobileNumber: res.mobileNumber,
        picture: res.picture,
        userUid: res.userUid,
        type: res.type,
        hours: res.hours,
        favorites: res.favorites,
        address: res.workingAddress,
        docs: [...res.documentos],
        tags: res.tags ? [...res.tags] : [],
        dni: res.dni,
        ratingList: res.ratingList,
        pictures: res.pictures,
        country: res.country ?? "Argentina",
        settings: res.settings ?? categorySettings,
      } as ICategory;
      return { category };
    } catch (error) {
      Logger.error(
        "Categories ::: getPublicCategory ::: Could not get category public",
        error,
        data,
      );
      throw new AppError("Could not get category public", error);
    }
  },
);

export const uploadCategoryDocument = createAsyncThunk(
  "categories/uploadCategoryDocument",
  async (data: UploadDocsParam) => {
    try {
      const { file, docs, label, categoryUid, categoryType } = data;
      const storageRef = ref(
        storage,
        `/categories/${categoryType.replace(
          " ",
          "_",
        )}/${categoryUid}/documentos/${label.replace(" ", "_").toLowerCase()}.${
          file.type.split("/")[1]
        }`,
      );

      await uploadBytes(storageRef, data.file);
      const url = await getDownloadURL(storageRef);

      if (!docs || docs.length === 0) return [];
      const documentos = docs.filter(
        (doc: any) =>
          doc.split("%2F").slice(-1)[0].split("?")[0].split(".")[0] !==
          label.replace(" ", "_").toLowerCase(),
      );

      documentos.push(url);

      const docRef = doc(db, "categorias", categoryUid);
      await updateDoc(docRef, {
        documentos,
      });

      return documentos;
    } catch (error) {
      Logger.error(
        "Categories ::: uploadCategoryDocument ::: Could not upload category document",
        error,
        data,
      );
      throw new AppError("Could not upload category document", error);
    }
  },
);

export const uploadPicture = createAsyncThunk<string, UploadPicParam>(
  "categories/uploadPicture",
  async (data: UploadPicParam) => {
    try {
      const { file, categoryType, categoryUid } = data;
      const storageRef = ref(
        storage,
        `/categories/${categoryType.replace(" ", "_")}/${categoryUid}/portada.${
          file.type.split("/")[1]
        }`,
      );

      await uploadBytes(storageRef, data.file);
      const url = await getDownloadURL(storageRef);

      const docRef = doc(db, "categorias", categoryUid);
      await updateDoc(docRef, {
        picture: url,
      });

      return url;
    } catch (error) {
      Logger.error(
        "Categories ::: uploadPicture ::: Could not upload category picture",
        error,
        data,
      );
      throw new AppError("Could not upload category picture", error);
    }
  },
);

const initialState: CategoryState = {
  categoryList: [],
  publicCategory: null,
  getStatus: STATUS.IDLE,
  getCategoryStatus: STATUS.IDLE,
  updateStatus: STATUS.IDLE,
  deleteDocumentStatus: STATUS.IDLE,
  uploadDocumentStatus: STATUS.IDLE,
  uploadPictureStatus: STATUS.IDLE,
};

export interface CategoryState {
  categoryList: [];
  publicCategory: ICategory | null;
  getStatus: STATUS;
  getCategoryStatus: STATUS;
  updateStatus: STATUS;
  deleteDocumentStatus: STATUS;
  uploadDocumentStatus: STATUS;
  uploadPictureStatus: STATUS;
}

export const CategoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    clearUpdateStatus(state): void {
      state.updateStatus = STATUS.IDLE;
    },
    clearDeleteDocument(state): void {
      state.deleteDocumentStatus = STATUS.IDLE;
    },
    clearCategorySelected(state: any) {
      state.categorySelected = null;
      state.categoryStatus = STATUS.IDLE;
      state.getCategoryStatus = STATUS.IDLE;
    },
    resetCategoryState(state: any) {
      state.categoryList = [];
      state.publicCategory = null;
      state.categorySelected = null;
      state.getStatus = STATUS.IDLE;
      state.updateStatus = STATUS.IDLE;
      state.deleteDocumentStatus = STATUS.IDLE;
      state.uploadDocumentStatus = STATUS.IDLE;
      state.uploadPictureStatus = STATUS.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCategory.pending, (state) => {
      Logger.log("Fetching updateCategory.....");
      state.updateStatus = STATUS.FETCHING;
    });
    builder.addCase(updateCategory.fulfilled, (state) => {
      Logger.log("Fetched updateCategory.....");
      state.updateStatus = STATUS.FETCH;
    });
    builder.addCase(updateCategory.rejected, (state) => {
      Logger.log("Failed updateCategory.....");
      state.updateStatus = STATUS.FETCH_ERROR;
    });
    // builder.addCase(deleteCategoryDocument.pending, (state, action) => {
    //     Logger.log("Fetching deleteCategoryDocument.....");
    //     state.deleteDocumentStatus = STATUS.FETCHING;
    // });
    // builder.addCase(deleteCategoryDocument.fulfilled, (state, action) => {
    //     Logger.log("Fetched deleteCategoryDocument.....");
    //     state.deleteDocumentStatus = STATUS.FETCH;
    // });
    // builder.addCase(deleteCategoryDocument.rejected, (state, action) => {
    //     Logger.log("Failed deleteCategoryDocument.....");
    //     state.deleteDocumentStatus = STATUS.FETCH_ERROR;
    // });
    // builder.addCase(getCategorySelected.pending, (state, action) => {
    //   Logger.log("Fetching getCategorySelected.....");
    //   state.getCategoryStatus = STATUS.FETCHING;
    // });
    // builder.addCase(getCategorySelected.fulfilled, (state, action) => {
    //   Logger.log("Fetched getCategorySelected.....");
    //   state.categorySelected = action.payload.category;
    //   state.getCategoryStatus = STATUS.FETCH;
    // });
    // builder.addCase(getCategorySelected.rejected, (state, action) => {
    //   Logger.log("Failed getCategorySelected.....");
    //   state.getCategoryStatus = STATUS.FETCH_ERROR;
    // });
    builder.addCase(getPublicCategory.pending, (state) => {
      Logger.log("Fetching getPublicCategory.....");
      state.getStatus = STATUS.FETCHING;
    });
    builder.addCase(getPublicCategory.fulfilled, (state, action) => {
      Logger.log("Fetched getPublicCategory.....");
      state.publicCategory = action.payload.category;
      state.getStatus = STATUS.FETCH;
    });
    builder.addCase(getPublicCategory.rejected, (state) => {
      Logger.log("Failed getPublicCategory.....");
      state.getStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(uploadCategoryDocument.pending, (state) => {
      Logger.log("Fetching uploadDocument.....");
      state.uploadDocumentStatus = STATUS.FETCHING;
    });
    builder.addCase(uploadCategoryDocument.fulfilled, (state) => {
      Logger.log("Fetched uploadDocument.....");
      /// TODO Refetch category
      // state.categorySelected!.docs = action.payload;
      state.uploadDocumentStatus = STATUS.FETCH;
    });
    builder.addCase(uploadCategoryDocument.rejected, (state) => {
      Logger.log("Failed uploadDocument.....");
      state.uploadDocumentStatus = STATUS.FETCH_ERROR;
    });
    builder.addCase(uploadPicture.pending, (state) => {
      Logger.log("Fetching uploadPicture.....");
      state.uploadPictureStatus = STATUS.FETCHING;
    });
    builder.addCase(uploadPicture.fulfilled, (state) => {
      Logger.log("Fetched uploadPicture.....");
      /// TODO Refetch category
      // state.categorySelected!.picture = action.payload;
      state.uploadPictureStatus = STATUS.FETCH;
    });
    builder.addCase(uploadPicture.rejected, (state) => {
      Logger.log("Failed uploadPicture.....");
      state.uploadPictureStatus = STATUS.FETCH_ERROR;
    });
  },
});

export const {
  clearCategorySelected,
  clearDeleteDocument,
  resetCategoryState,
  clearUpdateStatus,
} = CategoriesSlice.actions;

export default CategoriesSlice.reducer;
