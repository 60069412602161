// utils/api.ts
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from "axios";

type ApiRequest<T> = {
  endpoint: string;
  body: T;
};

export const apiCall = async <T, R>({
  endpoint,
  body,
}: ApiRequest<T>): Promise<R> => {
  try {
    const isDevelopment = process.env.REACT_APP_ENVIRONMENT === "development";

    if (isDevelopment) {
      // Use HTTP POST in development
      const response = await axios.post<R>(
        `http://localhost:5001/${endpoint}`,
        body,
      );
      return response.data;
    } else {
      // Use Firebase callable function in production
      const functions = getFunctions();
      const callable = httpsCallable(functions, endpoint);
      const response = await callable(body);
      return response.data as R;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};
