import React, {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  getDoc,
  doc,
  DocumentSnapshot,
  DocumentData,
  updateDoc,
  Timestamp,
  collection,
  query,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../service/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  type Auth,
  signInWithPopup,
  verifyBeforeUpdateEmail,
  updatePassword,
  FacebookAuthProvider,
  type User,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { auth } from "../service/firebase";
import { IUser } from "../interfaces/user";
import Logger from "../utils/Logger";
import { FirebaseError } from "firebase/app";
import { getAddressObject, getErrorMessage } from "../utils/functions";
import { AppError } from "../utils/errors";
import { Category, ICategory, ISettings } from "../interfaces/category";
import Loading from "../shared/Spinner";
import moment from "moment";
import IAppData from "../models/appData";
import Maintenance from "../shared/Maintenance";
import { categorySettings } from "../utils/constants";
import Splash from "../shared/Splash";
import { Unsubscribe } from "@reduxjs/toolkit";
import { apiCall } from "../utils/api";

export const AuthContext = React.createContext<AuthContextModel>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as AuthContextModel,
);

export interface AuthProviderProps {
  children?: ReactNode;
}

export interface AuthContextModel {
  auth: Auth;
  user: User | null;
  userData: IUser;
  appData: IAppData;
  logout: () => Promise<void>;
  resetPassword: (email: string | undefined) => Promise<void>;
  updateEmail: (email: string) => Promise<void> | undefined;
  updatePass: (password: string) => Promise<void> | undefined;
  signInWithFacebok: () => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signInWithApple: () => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  getCategoryFromUser: () => Promise<void>;
  refreshCategorySettings: () => Promise<void>;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export const UserStateContext = createContext<UserContextState>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as UserContextState,
);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<IUser | null>(null);
  const [appData, setAppData] = useState<IAppData | null>(null);
  const [appStatus, setAppStatus] = useState<boolean>(true);
  const [loading, setLoading] = useState(true);
  const [unsubscribeCategory, setUnsubscribeCategory] =
    useState<() => void | undefined>();
  const resetPassword = async (email?: string | null): Promise<void> => {
    if (!email) return;
    await apiCall<any, any>({
      endpoint: "createUserPasswordV2",
      body: { email },
    });
  };

  const signInWithFacebok = async (): Promise<void> => {
    try {
      const provider = new FacebookAuthProvider();
      const credentials = await signInWithPopup(auth, provider);
      setUser(credentials.user);
      await getUserInformation(credentials.user);
    } catch (error) {
      let message = "Algo salió mal";
      if (error instanceof FirebaseError) {
        message = getErrorMessage(error);
      }
      Logger.error(message, error);
      logout();
      throw new AppError(message, error);
    }
  };
  const signInWithGoogle = async (): Promise<void> => {
    try {
      const provider = new GoogleAuthProvider();
      const credentials = await signInWithPopup(auth, provider);
      setUser(credentials.user);
      await getUserInformation(credentials.user);
    } catch (error) {
      let message = "Algo salió mal";
      if (error instanceof FirebaseError) {
        message = getErrorMessage(error);
      }
      Logger.error(message, error);
      logout();
      throw new AppError(message, error);
    }
  };
  const signInWithApple = async (): Promise<void> => {
    try {
      const provider = new OAuthProvider("apple.com");
      const credentials = await signInWithPopup(auth, provider);
      setUser(credentials.user);
      await getUserInformation(credentials.user);
    } catch (error) {
      let message = "Algo salió mal";
      if (error instanceof FirebaseError) {
        message = getErrorMessage(error);
      }
      Logger.error(message, error);
      logout();
      throw new AppError(message, error);
    }
  };

  async function updateEmail(email: string): Promise<void> {
    if (user == null) return;
    await verifyBeforeUpdateEmail(user, email);
  }

  async function updatePass(password: string): Promise<void> {
    if (user == null) return;
    await updatePassword(user, password);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      await getInitialAppData();
      if (user && !userData) {
        await getUserInformation(user);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const logout = async (): Promise<void> => {
    localStorage.clear();
    if (unsubscribeCategory) {
      unsubscribeCategory();
    }
    await signOut(auth);
  };

  const signUp = async (email: string, password: string): Promise<void> => {
    try {
      const credentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      setUser(credentials.user);
      await getUserInformation(credentials.user);
    } catch (error) {
      let message = "Algo salió mal";
      if (error instanceof FirebaseError) {
        message = getErrorMessage(error);
      }
      Logger.error(message, error);
      logout();
      throw new AppError(message, error);
    }
  };

  const signIn = async (email: string, password: string): Promise<void> => {
    try {
      const credentials = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      setUser(credentials.user);
      await getUserInformation(credentials.user);
    } catch (error) {
      let message = "Algo salió mal";
      if (error instanceof FirebaseError) {
        message = getErrorMessage(error);
      }
      Logger.error(message, error);
      logout();
      throw new AppError(message, error);
    }
  };

  const getInitialAppData = async (): Promise<void> => {
    let resultAppData: IAppData = IAppData.fromConstants();
    try {
      const ref = collection(db, "app_data");
      const snapshot = await getDocs(query(ref));
      const rowAppData: Map<string, any> = new Map();
      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        rowAppData.set(doc.id, data);
      });
      const country =
        localStorage.getItem("country")?.toLowerCase() ?? "argentina";
      resultAppData = IAppData.fromJson(rowAppData, country);
      localStorage.setItem(
        "resultAppData",
        JSON.stringify(resultAppData.toJson()),
      );
      setAppData(resultAppData);
    } catch (e) {
      const appDataSaved = localStorage.getItem("resultAppData");
      if (appDataSaved) {
        resultAppData = JSON.parse(appDataSaved) as IAppData;
        setAppData(resultAppData);
      }
    } finally {
      setAppStatus(resultAppData.status.enable as boolean);
    }
  };

  const refreshCategorySettings = async (): Promise<void> => {
    try {
      if (!userData) return;
      const ref = doc(db, "categorias", userData.category.id);
      const docSnap = await getDoc(ref);
      const res = docSnap.data() as any;

      const settings = (res.settings ?? categorySettings) as ISettings;

      const updatedUserData = {
        ...userData,
        category: {
          ...userData.category,
          settings: settings,
        },
      } as IUser;

      setUserData(updatedUserData);
    } catch (e) {
      Logger.error("Error al obtener la configuración de la categoría", e);
    }
  };

  const getUserInformation = async (user: User): Promise<void> => {
    try {
      setLoading(true);
      const idToken = await user.getIdTokenResult();
      if (!idToken) {
        throw new Error("No se pudo obtener el token");
      }
      const docRef = doc(db, "users", user.uid);
      const response = await getDoc(docRef);
      const data = (response as DocumentSnapshot).data() as DocumentData;
      if (!data) {
        throw new Error("El usuario no existe, registrate desde la App.");
      }
      if (!data.categoryUid) {
        throw new Error("No tiene categoria asignada");
      }

      const docCatRef = doc(db, "categorias", data.categoryUid);
      const responseCategory = await getDoc(docCatRef);
      const dataCategory = (responseCategory as DocumentSnapshot).data() as any;
      if (!dataCategory) {
        throw new Error("El usuario no tiene categoria asignada.");
      }
      const unsubscribe = await startListeningCategory(data.categoryUid);
      setUnsubscribeCategory(() => unsubscribe);
      const localLastLogin = localStorage.getItem("lastLogin");
      const currentTime = moment();
      const lastLogin = Timestamp.fromDate(new Date());
      if (localLastLogin) {
        const savedTime = moment(localLastLogin);
        const duration = moment.duration(currentTime.diff(savedTime));
        const hours = duration.asHours();

        if (hours > 24) {
          await updateDoc(docCatRef, {
            lastLoginWeb: lastLogin,
          });
          localStorage.setItem("lastLogin", currentTime.toISOString());
        }
      } else {
        await updateDoc(docCatRef, {
          lastLoginWeb: lastLogin,
        });
        localStorage.setItem("lastLogin", currentTime.toISOString());
      }
      const category = Category.fromFirestore(
        responseCategory.id as string,
        dataCategory,
      );
      const userResponse = {
        userUid: user.uid,
        email: data.email,
        name: data.name,
        bio: data.bio,
        category: category,
      } as IUser;
      setUserData(userResponse);
      localStorage.setItem("country", userResponse.category.country);
      localStorage.setItem("userUid", userResponse.userUid);
      localStorage.setItem("token", idToken.token);
      localStorage.setItem("categoryUid", userResponse.category.categoryUid);
      localStorage.setItem("categoryType", dataCategory.categoryType);
      localStorage.setItem("categoryTitle", userResponse.category.title);
      localStorage.setItem("enabled", userResponse.category.enable.toString());
      localStorage.setItem(
        "timezone",
        userResponse.category.timezone ?? "America/Argentina/Buenos_Aires",
      );
    } catch (e) {
      Logger.error("Error al obtener la información del usuario", e);
      await logout();
      throw new AppError("Error al obtener la información del usuario", e);
    } finally {
      setLoading(false);
    }
  };

  const startListeningCategory = async (
    categoryUid: string,
  ): Promise<Unsubscribe> => {
    const ref = doc(db, "categorias", categoryUid);

    const unsubsc = onSnapshot(
      ref,
      (doc) => {
        const category = Category.fromFirestore(
          doc.id,
          doc.data() as DocumentSnapshot,
        );
        const updatedUserData = {
          ...userData,
          category: category,
        } as IUser;
        setUserData(updatedUserData);
      },
      (error) => {
        Logger.error("Could not stream category", error);
      },
    );
    return unsubsc;
  };

  const getCategoryFromUser = async (): Promise<void> => {
    try {
      if (userData == null) return;
      const docCatRef = doc(db, "categorias", userData.category.categoryUid);
      const responseCategory = await getDoc(docCatRef);
      const dataCategory = (responseCategory as DocumentSnapshot).data() as any;
      if (!dataCategory) {
        throw new Error("El usuario no tiene categoria asignada.");
      }
      const category = Category.fromFirestore(
        responseCategory.id as string,
        dataCategory,
      );

      setUserData({
        ...userData,
        category: category,
      });
    } catch (e) {
      Logger.error(
        "Error al obtener la información de la categoria del usuario",
        e,
      );
      await logout();
    }
  };

  const values = {
    auth,
    userData: userData!,
    appData: appData!,
    user,
    logout,
    resetPassword,
    updateEmail,
    updatePass,
    signIn,
    signInWithFacebok,
    signInWithGoogle,
    signInWithApple,
    signUp,
    getCategoryFromUser,
    refreshCategorySettings,
  };

  return (
    <AuthContext.Provider value={values}>
      {!loading && appStatus && children}
      {loading && appStatus && <Splash />}
      {!appStatus && (
        <Maintenance
          message={
            (appData?.status.message as string) ?? "Estamos en mantenimiento"
          }
        />
      )}
    </AuthContext.Provider>
  );
};
