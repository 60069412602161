import React from "react";
import { Spinner } from "react-bootstrap";
import "./Splash.css"; // Import the CSS for styling
import { useAuth } from "../context/AuthContext";

const Splash = () => {
  const auth = useAuth();

  return (
    <div className="splash-screen">
      <div className="splash-screen-content">
        <img
          src={require("../../assets/full_logo.png")}
          alt="MascotaMás"
          className="main-logo-splash"
        />
        {auth.userData?.category?.country &&
          auth.userData?.category?.country.toLowerCase() !== "argentina" && (
            <>
              <p className="powered-by-text">Powered by</p>
              <img
                src={require(`../../assets/${auth.userData.category.country.toLowerCase()}.png`)}
                alt="PawCare"
                className="powered-by-logo"
              />
            </>
          )}
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
    </div>
  );
};

export default Splash;
